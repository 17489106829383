import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import SecondMenu from '../../Components/SecondMenu';
import SlideMenu from '../../Components/SlideMenu';
import firebase from '../../firebase';
import {
    Box,
    Button, Container,
    Grid, Paper, TableContainer, TextField, Typography,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import SearchIcon from '@mui/icons-material/Search';
import slugify from '../../util/common/slugify';

function ManageItems() {
    const [itemList, setItemList] = useState([]);
    const [cateList, setCateList] = useState([])
    const [search, setSearch] = useState('')
    const [category, setCategory] = useState('')
    const [searchQuery, setSearchQuery] = useState("")

    // const [filterData, setFilterData] = useState([])
    function sortByDate(a, b) {
        return a["timeStamp"] - b["timeStamp"];
    }

    const loadData = () => {
        firebase.database().ref('items/').on('value', (snapshot) => {
            var snapVal = snapshot.val();
            const itemsArry = [];

            for (let id in snapVal) {
                itemsArry.push({ id, ...snapVal[id] })
            }



            setItemList(itemsArry)
            // setFilterData(itemsArry)
            firebase.database().ref('categories/').on('value', (snapshot) => {
                var snapVal = snapshot.val();
                const fatched = [];

                for (let id in snapVal) {
                    fatched.push({ id, ...snapVal[id] })
                }
                setCateList(fatched)
            })
        })
    }

    useEffect(() => {
        loadData()
    }, [])


    const handleChange = (event) => {
        setSearchQuery(event.target.value)
    }

    useEffect(() => {
        searchFilter()
    }, [searchQuery])

    const searchFilter = () => {
        if (searchQuery) {
            const newData = itemList.filter(item => {
                // console.log(item?.timeStamp)
                const time = String(new Date(item?.timeStamp)).replace("GMT+0530 (India Standard Time)", "")
                    return(
                        item.id === searchQuery ||
                            item?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            item?.category?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            time?.toLocaleLowerCase().includes(searchQuery?.toLocaleLowerCase())
                    )
        }
                );
    setItemList(newData);
}
            else if (searchQuery === '') {
    loadData()
}
        }

return (

    <>
        <SlideMenu title="Edit Catrgories" url="/edit-categories" />
        <Box
            sx={{
                bgcolor: 'background.default',
                // width: "100%"
                display: "flex",
                flex: 1,
            }}
            className="main-display edit-categories"
        >
            <div className="main-child">
                <SecondMenu title="Manage orders" url="/orders" />
                <div className="container smart-card">
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography
                            sx={{ m: 1, color: 'text.primary', }}
                            variant="h4"
                        >
                            All Products
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Link to="/add-crops">
                                <Button variant='contained'>
                                    Add Crop or Item
                                </Button>
                            </Link>
                        </Box>
                    </Box>

                    <Box>
                        <TextField onChange={handleChange} label="Search" sx={{ width: "400px" }} placeholder='Search by orderID' />
                        <Button onClick={searchFilter} sx={{ height: "56px", marginLeft: 2 }} variant='contained' color="primary">
                            <SearchIcon />
                        </Button>
                    </Box>


                    <Box sx={{ color: 'text.primary', pb: 10, mt: 3, }} className="table-responsive" >
                        <TableContainer sx={{ minWidth: 650, backgroundColor: "#111827" }} component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead sx={{ backgroundColor: "#1f2937" }}>
                                    <TableRow>
                                        <TableCell>Sr No.</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Price.</TableCell>
                                        <TableCell>Unit</TableCell>
                                        <TableCell>Time Stamp</TableCell>
                                        <TableCell>Last Update</TableCell>
                                        <TableCell>Quantity.</TableCell>
                                        <TableCell>Categorie</TableCell>
                                        <TableCell colSpan={3} className="text-center">Opration</TableCell>
                                    </TableRow>
                                </TableHead>
                                <tbody>

                                    {
                                        itemList.sort(sortByDate).reverse().map((list, index) => {
                                            return (
                                                <ItemsTr key={index} category={category} cateList={cateList} list={list} index={index} />
                                            )
                                        })
                                    }

                                </tbody>
                            </Table>
                        </TableContainer>
                    </Box>

                </div>
            </div>
        </Box>
    </>

)
}
const ProductTr = (props) => {
    const [open, setOpen] = useState(false)
    const order = props.item
    const users = props.users
    const products = props.products
    const [changedStatus, setChangedStatus] = useState(order.orderStatus)

    var timestamp = order.TIMESTAMP
    var rawTime = String(new Date(timestamp))
    var timestamp = rawTime.replace("GMT+0530 (India Standard Time)", "")
    return (
        <>
            <TableRow
                hover
                key={order.id}
            >
                <TableCell>
                    <Button onClick={() => open === true ? setOpen(false) : setOpen(true)} >
                        <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />
                    </Button>
                </TableCell>
                <TableCell>{props.index + 1}</TableCell>
                <TableCell>{order.name}</TableCell>
                <TableCell>&#8377; {order.price}</TableCell>
                <TableCell>{order.unit}</TableCell>
                <TableCell>{String(new Date(order.timeStamp)).replace("GMT+0530 (India Standard Time)", "")}</TableCell>
                <TableCell>{order.quantity}</TableCell>
                <TableCell>{order.category}</TableCell>
                <TableCell>
                    <Link
                        to={{
                            pathname: '/edit-item-detail',
                            state: { data: order, category: props.cateList }
                        }
                        }
                    ><i style={{ cursor: 'pointer', margin: '0 5px' }} className="fa fa-edit text-success"></i></Link>
                </TableCell>
                <TableCell>
                    {/* <i onClick={deleteItem} style={{ cursor: 'pointer', margin: '0 5px' }} className="fa fa-trash text-danger"></i> */}
                </TableCell>
                <TableCell>
                    {/* <i onClick={showDetail} style={{ cursor: 'pointer', margin: '0 5px' }} className="fa fa-info-circle text-primary"></i> */}
                </TableCell>
            </TableRow>
            {
                open &&
                <TableRow>
                    <TableCell colSpan={20}>
                        <Container>
                            <Box paddingY={"20px"}>
                                <Grid container spacing={2}>

                                    <Grid md={4}>
                                        <Typography variant='h6' marginBottom="12px">
                                            Update Order Status
                                        </Typography>
                                        <Divider />
                                        <br />
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Order Status</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                defaultValue={order.orderStatus}
                                                label="Order Status"
                                                value={changedStatus}
                                                onChange={(e) => { setChangedStatus(e.target.value) }}
                                            >
                                                <MenuItem selected value={"Order Placed"}>Order Placed</MenuItem>
                                                <MenuItem value={"Dispatched"}>Dispatched</MenuItem>
                                                <MenuItem value={"Delivered"}>Delivered</MenuItem>
                                            </Select>
                                            <Box marginY={"10px"}>
                                                <Button
                                                    // onClick={upadeStatus} 
                                                    disabled={changedStatus === order.orderStatus} style={{ textTransform: "none" }} variant='contained'>
                                                    Update Status
                                                </Button>
                                            </Box>
                                        </FormControl>
                                    </Grid>



                                    {/* <StatusUpdater order={order} currentUser={currentUser} /> */}
                                </Grid>
                            </Box>
                        </Container>
                    </TableCell>
                </TableRow >
            }
        </>
    )
}
export function ItemsTr(props) {
    // console.log(props.category !== props.list.category,props.category === '', props.category)

    const deleteFromOrderTrack = (productId) => {
        return new Promise((resolve, reject) => {
            firebase.database().ref("order-track").once("value", (snap) => {
                const snapVal = snap.val();
                let promises = [];
                for (let id in snapVal) {
                    const order = snapVal[id];
                    if (order.itemId === productId) {
                        console.log(`Deleting order-track with ID: ${id}, where productId matches itemId`);
                        promises.push(firebase.database().ref("order-track").child(id).remove());
                    }
                }
                Promise.all(promises).then(resolve).catch(reject);
            }).catch((error) => {
                reject(error);
            });
        });
    };



    const deleteItem = () => {
        Swal.fire(
            'Do you really want to delete this item?',
            '',
            'warning'
        ).then(() => {
            var imgUrl = props.list.imgUrl;
            // console.log('imgurl', imgUrl)
            // var imageName = imgUrl?.split("kisaandarshan-test.appspot.com/o/products%2FmwnEIzB9pnNPqMYKKyMRM2P8r9b2%2F1")[1].split("?")[0];

            // console.log('imgename',imageName)

            const extractImageName = (url) => {
                try {
                    // Decode the URL (in case it's URL encoded)
                    const decodedUrl = decodeURIComponent(url);

                    // Split the URL at '/o/' to get the storage path
                    const path = decodedUrl.split('/o/')[1];

                    // Remove the query parameters (e.g., '?alt=media&token=...')
                    const fileName = path.split('?')[0];

                    return fileName; // This gives you the path to the image
                } catch (error) {
                    console.error('Error extracting image name:', error);
                    return null;
                }
            };

            const imageName = extractImageName(imgUrl);
            console.log('Extracted Image Path:', imageName);


            // Construct the correct path to your image in Firebase Storage
            var imagePath = `products/${props.list.sellerUID}/${imageName}`;

            console.log('imgepath', imagePath)

            firebase.storage().ref(imagePath).delete()
                .then(() => {
                    console.log("Image deleted successfully.");
                })
                .catch((error) => {
                    console.log("Error deleting image (it might not exist):", error.message);
                })
                .finally(() => {
                    const ItemRef = firebase.database().ref('items/').child(props.list.id);
                    ItemRef.remove().then(() => {
                        deleteFromOrderTrack(props.list.id)
                        Swal.fire(
                            'Deleted Successfully',
                            "",
                            'success'
                        );
                    }).catch(error => {
                        Swal.fire(
                            'Error deleting item from database',
                            error.message,
                            'error'
                        );
                    });
                }
                );
        });
    };



    const showDetail = () => {

        const list = props.list
        const theDetial = () => {
            var detail = ""
            Object.keys(list).map((col) => { detail = detail + `${col}: ${list[col]} <br>` })
            return detail
        }

        Swal.fire("The detail", `<div className="text-left">${theDetial()}</div>`, "info")
        console.log(list)
    }
    var lastUpdate = String(new Date(props.list.lastUpdate)).replace("GMT+0530 (India Standard Time)", "")
    if (props.category === '') {
        return (
            <TableRow key={props.index + 6}>
                <TableCell>{props.index + 1}</TableCell>
                <TableCell>{props.list.name}</TableCell>
                <TableCell>&#8377; {props.list.price}</TableCell>
                <TableCell>{props.list.unit}</TableCell>
                <TableCell>{String(new Date(props.list.timeStamp)).replace("GMT+0530 (India Standard Time)", "")}</TableCell>
                <TableCell>{lastUpdate === "Invalid Date" ? "" : lastUpdate}</TableCell>
                <TableCell>{props.list.quantity}</TableCell>
                <TableCell>{props.list.category}</TableCell>
                <TableCell>
                    <Link
                        to={{
                            pathname: '/edit-item-detail',
                            state: { data: props.list, category: props.cateList }
                        }
                        }
                    ><i style={{ cursor: 'pointer', margin: '0 5px' }} className="fa fa-edit text-success"></i></Link>
                </TableCell>
                <TableCell>
                    <i onClick={deleteItem} style={{ cursor: 'pointer', margin: '0 5px' }} className="fa fa-trash text-danger"></i>
                </TableCell>
                <TableCell>
                    <i onClick={showDetail} style={{ cursor: 'pointer', margin: '0 5px' }} className="fa fa-info-circle text-primary"></i>
                </TableCell>
                <TableCell>
                    <a target='_blank' href={`https://www.ekisandarshan.in/products/${slugify(props.list.category || "")}/${slugify(props.list.name || "")}/${props.list.id}`}>
                        <i onClick={showDetail} style={{ cursor: 'pointer', margin: '0 5px' }} className="fa-solid fa-eye text-primary"></i>
                    </a>
                </TableCell>
            </TableRow>
        )
    }
    else {
        return (
            props.category !== props.list.category ? '' :
                <TableRow key={props.index}>
                    <TableCell>{props.index + 1}</TableCell>
                    <TableCell>{props.list.name}</TableCell>
                    <TableCell>&#8377; {props.list.price}</TableCell>
                    <TableCell>{props.list.unit}</TableCell>
                    <TableCell>{String(new Date(props.list.timeStamp)).replace("GMT+0530 (India Standard Time)", "")}</TableCell>
                    <TableCell>{props.list.quantity}</TableCell>
                    <TableCell>{props.list.category}</TableCell>
                    <TableCell>
                        <Link
                            to={{
                                pathname: '/edit-item-detail',
                                state: { data: props.list, category: props.cateList }
                            }
                            }
                        ><i style={{ cursor: 'pointer', margin: '0 5px' }} className="fa fa-edit text-success"></i></Link>
                    </TableCell>
                    <TableCell>
                        <i onClick={deleteItem} style={{ cursor: 'pointer', margin: '0 5px' }} className="fa fa-trash text-danger"></i>
                    </TableCell>
                    <TableCell>
                        <i onClick={showDetail} style={{ cursor: 'pointer', margin: '0 5px' }} className="fa fa-info-circle text-primary"></i>
                    </TableCell>
                </TableRow>
        )
    }
}
export default ManageItems

