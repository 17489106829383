import React, { useEffect, useState } from 'react'
import SecondMenu from '../../Components/SecondMenu'
import SlideMenu from '../../Components/SlideMenu'
import firebase from '../../firebase'
import Swal from 'sweetalert2'
import slugify from '../../util/common/slugify'
/* eslint-disable */
function ItemsToVerify() {
    const [itemList, setItemList] = useState([]);
    const [user, setUser] = useState([])
    const [category, setCategory] = useState('')
    const [filterData, setFilterData] = useState([])
    useEffect(() => {
        const loadData = () => {
            firebase.database().ref('item-to-verify/').on('value', (snapshot) => {
                var snapVal = snapshot.val();
                const itemsArry = [];

                for (let id in snapVal) {
                    itemsArry.push({ id, ...snapVal[id] })
                }
                setItemList(itemsArry)

                firebase.database().ref('categories/').on('value', (snapshot) => {
                    var snapVal = snapshot.val();
                    const fatched = [];

                    for (let id in snapVal) {
                        fatched.push({ id, ...snapVal[id] })
                    }
                    setCategory(fatched)
                })
                firebase.database().ref('users/').on('value', (snapshot) => {
                    var snapVal = snapshot.val();
                    const userdata = [];

                    for (let id in snapVal) {
                        userdata.push({ id, ...snapVal[id] })
                    }
                    setUser(userdata)
                })
            })
        }
        loadData()
    }, [])

    return (
        <>
            <SlideMenu title="Edit Catrgories" url="/edit-categories" />
            <div className="main-display edit-categories">
                <div className="main-child">

                    <SecondMenu title="Manage Items" url="/manage-items" />
                    <div className="card-wrap">


                        {
                            itemList.map((list, index) => {
                                return (
                                    <ProductCard key={index} category={category} user={user} list={list} index={index} />
                                )
                            })
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

const ProductCard = (props) => {
    const [currentQuantity, setCurrentQuantity] = useState(1)
    const [isAdding, setIsAdding] = useState(false)
    const [sellerAdd, setSellerAdd] = useState([])
    const [selectImg, setSelectImg] = useState(null)
    // const navigate = navigator()

    // console.log('received props are', props)
    const itemData = props.list
    // console.log('props', itemData)
    const accept = () => {
        var propList = props.list
        const itemData = { ...propList }
        delete itemData?.cropName
        delete itemData?.id
        firebase.database().ref("/items").push(itemData).then((e) => {
            
            Swal.fire("Item listed seccessfully", "", "success").then(() => {
                firebase.database().ref("/item-to-verify").child(propList.id).remove().then(() => {
                    const itemRef = firebase.database().ref(`users/${itemData.sellerUID}/notifications`);
                    itemRef.push({
                        title: "Your Product is Listed",
                        description: "You can view your product by clicking View Product",
                        type: "other",
                        buttonText: "View Product",
                        buttonUrl: `products/${slugify(itemData?.category)}/${itemData?.name}/${e.key}`,
                        timestamp: Date.now()
                    })
                })
            })
        })

    }
    useEffect(() => {
        firebase.database().ref('users/').child(itemData?.sellerUID).on('value', (snapshot) => {
            setSellerAdd(snapshot.val());
        })
    }, [])

    const discrad = async () => {
        var propList = props.list

        // delete itemData.cropName
        const { value: reason } = await Swal.fire({
            title: 'Select field validation',
            input: 'select',
            inputOptions: {
                img: 'Image is not proper',
                price: 'Over priced',
                certificate: 'Certificate Number Invalid',
                description: 'Description is Wrong',

            },
            inputPlaceholder: 'Select a reason for Rejection',
            showCancelButton: true,

        })

        if (reason) {
            const itemData = {
                ...propList,
                reason: reason
            }
            firebase.database().ref("/users").child(propList.sellerUID).child("item_rejected").push(itemData).then(() => {
                firebase.database().ref("/item-to-verify").child(propList.id).remove()
            })
        }



    }
    const showDetial = () => {
        delete sellerAdd.farmerData
        delete sellerAdd.product_for_sell
        delete sellerAdd.item_rejected
        // delete sellerAdd.phone
        delete sellerAdd.cart
        // delete sellerAdd.email
        // delete sellerAdd.userId
        // delete sellerAdd.address
        delete sellerAdd.orders
        delete sellerAdd.cooperateData


        const theDetial = () => {
            var detail = ""
            Object.keys(sellerAdd).map((col) => { detail = detail + `${col}: ${sellerAdd[col]} <br>` })
            return detail
        }

        Swal.fire("The detail", `<div className="text-left">${theDetial()}</div>`, "info")
    }
    return (
        <>
            <div className="product">
                <div className="img-box-pro">
                    <img onClick={showDetial} src={selectImg ? selectImg : itemData?.imgUrl} alt="wheat" className="w-[100%] h-[300px]" />
                    {
                        itemData?.organic === "yes" && <><div className="green-box"></div></>
                    }
                </div>
                <div className='flex gap-2 mt-2'>
                <img src={itemData?.imgUrl} alt='' className='w-[70px] h-[70px] rounded-md cursor-pointer' onClick={()=>setSelectImg(itemData?.imgUrl)} />
                    {
                        itemData?.productImages &&
                        itemData?.productImages.map((imgsrc,i)=>{
                            return (
                                <img src={imgsrc?.url} alt='' className='w-[70px] h-[70px] rounded-md cursor-pointer' onClick={()=>setSelectImg(imgsrc?.url)} />
                            )
                        })
                    }
                </div>
                <div className="pro-detail">
                    <h3 className="pro-name">{itemData?.cropName || itemData?.name}</h3>
                    <span>
                        <b>Category :</b> {itemData?.category}
                    </span>
                    <h3 className="pro-price"> ₹ {itemData?.price} / {itemData.unit}</h3>
                    <span>
                        Seller Name: {sellerAdd?.name}
                    </span>
                    <br />
                    <p style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        width: "100%"
                    }}>
                        Seller id: {itemData?.sellerUID}
                    </p>
                    <span><b>Variety/Make:</b> {itemData?.variety ? itemData?.variety : "Not Mentioned"}</span> <br />
                    <span><b>Description:</b> {itemData?.description ? itemData?.description : "Not Mentioned"}</span> <br />
                    <span>
                        <b>Sold by:</b> {sellerAdd?.userType}
                    </span>
                    {
                        itemData.organic === "yes" && <><h3 className="pro-name">Organic Certi No. :{itemData?.certificateNo}</h3></>
                    }
                    <p className="pro-desc"> <i className="fa fa-map-marker-alt"></i> {sellerAdd?.district}, {sellerAdd?.state}</p>
                </div>

                <div className="d-flex justify-content-evenly">
                    <div onClick={accept} className="btn btn-success"><i className="fa fa-check"></i></div>
                    <div onClick={discrad} className="btn btn-danger"><i className="fa fa-times"></i></div>
                </div>

            </div>

        </>
    )
}
export default ItemsToVerify

