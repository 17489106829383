import firebase from "../../firebase";

// Fetch additional details for a user
const fetchUserDetails = async (userId) => {
  try {
    const snapshot = await firebase.database().ref(`users/${userId}`).once("value");
    return snapshot.exists() ? snapshot.val() : null;
  } catch (error) {
    console.error(`Error fetching user details for userId: ${userId}`, error);
    return null;
  }
};

// Fetch additional details for an item
export const fetchItemDetails = async (createdAt) => {
  // try {
  //   const snapshot = await firebase.database().ref(`items/${itemId}`).once("value");
  //   return snapshot.exists() ? snapshot.val() : null;
  // } catch (error) {
  //   console.error(`Error fetching item details for itemId: ${itemId}`, error);
  //   return null;
  // }
    try {
      const snapshot = await firebase.database()
        .ref('items')
        .orderByChild('createdAt')  // Order by createdAt field
        .equalTo(createdAt)         // Match the exact createdAt value
        .once("value");
  
      if (snapshot.exists()) {
        const items = [];
        snapshot.forEach((childSnapshot) => {
          const itemId = childSnapshot.key;  // Get the key (itemId)
          const itemData = childSnapshot.val();  // Get the data of the item
          
          // Add the id field directly to the item data
          itemData.id = itemId;
  
          items.push(itemData);  // Store the item in the array
        });
  
        return items;  // Return the items with the id field included directly in the data
      } else {
        console.log('No item found with the specified createdAt.');
        return null;
      }
    } catch (error) {
      console.error(`Error fetching item with createdAt: ${createdAt}`, error);
      return null;
    }
  
};

export const updateItemDetails = async (itemId, updatedFields) => {
  try {
    const itemRef = firebase.database().ref(`items/${itemId}`);

    // Use update() to modify only the specified fields
    await itemRef.update(updatedFields);

    console.log(`Item details updated successfully for itemId: ${itemId}`);
    return true;  // Indicate successful update
  } catch (error) {
    console.error(`Error updating item details for itemId: ${itemId}`, error);
    return false;  // Indicate error occurred
  }
};

// Fetch all orders from the "order-track" collection
export const fetchAllOrders = async () => {
  try {
    const snapshot = await firebase.database().ref("order-track").once("value");

    if (snapshot.exists()) {
      const orders = [];
      const fetchDetailsPromises = []; // To handle async lookups

      snapshot.forEach((childSnapshot) => {
        const orderData = childSnapshot.val();
        const orderId = childSnapshot.key;

        // Prepare promises for fetching additional details
        const sellerPromise = fetchUserDetails(orderData.sellerId);
        const buyerPromise = fetchUserDetails(orderData.buyerId);
        const itemPromise = fetchItemDetails(orderData.itemId);

        fetchDetailsPromises.push(
          Promise.all([sellerPromise, buyerPromise, itemPromise]).then(
            ([sellerDetails, buyerDetails, itemDetails]) => {
              orders.push({
                id: orderId,
                ...orderData,
                sellerName: sellerDetails?.name || "Unknown Seller",
                buyerName: buyerDetails?.name || "Unknown Buyer",
                itemName: itemDetails?.name || "Unknown Item",
              });
            }
          )
        );
      });

      // Wait for all additional detail lookups to complete
      await Promise.all(fetchDetailsPromises);

      return orders;
    } else {
      console.log("No orders found.");
      return [];
    }
  } catch (error) {
    console.error("Error fetching orders:", error);
    return [];
  }
};


// Send a message to a specific order
export const sendMessage = async (orderId, message) => {
  try {
    const messagesRef = firebase.database().ref(`order-track/${orderId}/requests/0/messages`);
    await messagesRef.push(message);
    console.log("Message sent successfully.");
  } catch (error) {
    console.error("Error sending message:", error);
    throw new Error("Error sending message");
  }
};



// Fetch orders from the "orders" collection
export const orders = async () => {
    try {
      const snapshot = await firebase.database().ref("orders").once("value");
  
      if (snapshot.exists()) {
        const orders = [];
        snapshot.forEach((childSnapshot) => {
          const orderData = childSnapshot.val();
          orders.push({
            id: childSnapshot.key,
            ...orderData,
          });
        });
        return orders;
      } else {
        console.log("No orders found.");
        return [];
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
      return [];
    }
  };

  export const fetchHelpSupportContent = async () => {
    try {
      const snapshot = await firebase.database().ref("helpSupport").once("value");
  
      if (snapshot.exists()) {
        const helpSupportData = [];
  
        snapshot.forEach((childSnapshot) => {
          const data = childSnapshot.val();
          const index = childSnapshot.key;
          
          helpSupportData.push({
            index,
            ...data,
          });
        });
  
        return helpSupportData;
      } else {
        console.log("No help support content found.");
        return [];
      }
    } catch (error) {
      console.error("Error fetching help support content:", error);
      return [];
    }
  };

  // Fetch the name of an item by its ID
export const fetchItemName = async (itemId) => {
  if(itemId){
  try {
    const snapshot = await firebase.database().ref(`items/${itemId}`).once("value");
    if (snapshot.exists()) {
      const itemData = snapshot.val();
      return itemData.name || null; // Return only the name field, or null if name is not found
    } else {
      console.log("Item not found.");
      return null;
    }
  } catch (error) {
    console.error(`Error fetching item name for itemId: ${itemId}`, error);
    return null;
  }
}else{
  return "undefined !"
}
};
