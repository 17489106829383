import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import SecondMenu from '../Components/SecondMenu';
import SlideMenu from '../Components/SlideMenu';
import firebase from '../firebase';
import {
    Box,
    Button, Container,
    Grid, Paper, TableContainer, TextField, Tooltip, Typography,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useDataLayerValue } from '../DataLayer/DataLayer';
import slugify from '../util/common/slugify';
import { fetchItemDetails, orders } from '../util/orders/orders';
import SearchIcon from '@mui/icons-material/Search';


function ManageOrders() {
    const [itemList, setItemList] = useState([]);
    const [cateList, setCateList] = useState([])
    const [search, setSearch] = useState('')
    const [category, setCategory] = useState('')
    const [filterData, setFilterData] = useState([])
    const [searchQuery, setSearchQuery] = useState("")

    // const [{ placed_orders }] = useDataLayerValue()

    const [placed_orders, setplaced_orders] = useState([]);

    const getOrdersList = async () => {
        const data = await orders();
        const allOrders = Object.values(data);
        const allSortedOrders = allOrders.sort((a, b) => b.
            timeStamp - a.timeStamp)
        setplaced_orders(allSortedOrders)
    }

    useEffect(() => {
        getOrdersList();
    }, [])

    const handleChange = (event) => {
        setSearchQuery(event.target.value)
    }

    useEffect(() => {
        searchFilter()
    }, [searchQuery])

    const searchFilter = () => {
        // console.log('filtered orders', placed_orders)
        if (searchQuery) {
            const query = searchQuery.toLowerCase();
            // console.log('q', query)
            // const newData = placed_orders.filter(item => item.id === searchQuery)
            const newData = placed_orders.filter((item) => {
                const time = String(new Date(item?.timeStamp)).replace("GMT+0530 (India Standard Time)", "")
                return (
                    item?.id === searchQuery ||
                    item?.item?.sellerName?.toLowerCase().includes(query) ||
                    item?.item?.name?.toLowerCase().includes(query) ||   
                    time?.toLocaleLowerCase().includes(query)
                )
            })
            // console.log('data', newData)
            // if(newData.length > 0){
            setplaced_orders(newData)
            // }
        } else if (searchQuery === '') {
            getOrdersList()
        }
    }

    return (

        <>
            <SlideMenu title="Edit Catrgories" url="/edit-categories" />
            <Box
                sx={{
                    bgcolor: 'background.default',
                    // width: "100%"
                    display: "flex",
                    flex: 1,
                }}
                className="main-display edit-categories"
            >
                <div className="main-child">
                    <SecondMenu title="Manage orders" url="/orders" />
                    <div className="container smart-card">
                        <Box sx={{ display: "flex", mb: 2, justifyContent: "space-between" }}>
                            <Typography
                                sx={{ m: 1, color: 'text.primary', }}
                                variant="h4"
                            >
                                orders
                            </Typography>
                        </Box>

                        <Box>
                            <TextField onChange={handleChange} label="Search" sx={{ width: "400px" }} placeholder='Search by orderID, sellerName, productName' />
                            <Button onClick={searchFilter} sx={{ height: "56px", marginLeft: 2 }} variant='contained' color="primary">
                                <SearchIcon />
                            </Button>
                        </Box>

                        <Box sx={{ color: 'text.primary', pb: 10, mt: 3, }} className="table-responsive" >
                            <TableContainer sx={{ minWidth: 650, backgroundColor: "#111827" }} component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead sx={{ backgroundColor: "#1f2937" }}>
                                        <TableRow>
                                            <TableCell ></TableCell>
                                            <TableCell align="right">Sr No.</TableCell>
                                            <TableCell>Item Name</TableCell>
                                            <TableCell>Status Code</TableCell>
                                            <TableCell>Quantity.</TableCell>
                                            <TableCell>Unit</TableCell>
                                            <TableCell>Price.</TableCell>
                                            <TableCell>Time Stamp</TableCell>
                                            <TableCell colSpan={3} className="text-center">Opration</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {placed_orders.map((order, index) => {
                                            return <ProductTr index={index} item={order} />
                                        })}
                                    </TableBody>
                                </Table>


                            </TableContainer>
                        </Box>
                    </div>
                </div>
            </Box>
        </>

    )
}
const ProductTr = (props) => {
    const [open, setOpen] = useState(false)
    const order = props?.item
    const users = props?.users
    const products = props?.products
    const [changedStatus, setChangedStatus] = useState(order?.orderStatus)
    const [currentBuyer, setCurrentBuyer] = useState([])
    const [currentProduct, setCurrentProduct] = useState([])
    const [curruntSeller, setCurruntSeller] = useState([])



    useEffect(() => {
        if (order?.buyerId) {
            firebase.database().ref("users").child(order?.buyerId).on("value", (snapshot) => {
                setCurrentBuyer(snapshot.val());
            });
        }

        if (order?.sellerId) {
            firebase.database().ref("users").child(order?.sellerId).on("value", (snapshot) => {
                setCurruntSeller(snapshot.val());
            });
        }

    }, [order?.buyerId, order?.sellerId]);

    const openInNewTab = async (itemid) => {
        //  console.log('itemid', itemid?.item?.createdAt)
        const order = await fetchItemDetails(itemid?.item?.createdAt);
        // console.log('order', order[0]?.id)
        if (order[0]?.id) {
            window.open(`https://www.ekisandarshan.in/products/${slugify(order[0]?.category || "")}/${slugify(order[0]?.name || "")}/${order[0]?.id}`, '_blank');
        }
    };

    // console.log('order item is', order)

    const deleteItem = () => {
        if (!order?.id) {
            console.error("Order ID not found");
            return;
        }
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it'
        }).then((result) => {
            if (result.isConfirmed) {

                firebase.database().ref('orders/').child(order?.id).remove()
            }

        })

    }
    var timestamp = order?.timeStamp
    var rawTime = String(new Date(timestamp))
    var timestamp = rawTime.replace("GMT+0530 (India Standard Time)", "")
    if (currentProduct) {
        // console.log(curruntSeller)
        return (
            <>
                <TableRow
                    hover
                    key={order?.id}
                >
                    <TableCell>
                        <Button onClick={() => open === true ? setOpen(false) : setOpen(true)} >
                            <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />
                        </Button>
                    </TableCell>
                    <TableCell>{props?.index + 1}</TableCell>
                    <TableCell>{order?.name || order?.item?.name}</TableCell>
                    <TableCell>{order?.orderStatus}</TableCell>
                    <TableCell>{order?.quantity}</TableCell>
                    <TableCell>{order?.unit || order?.item?.unit}</TableCell>
                    <TableCell>&#8377; {order?.price || order?.item?.price}</TableCell>
                    <TableCell>{String(new Date(order.timeStamp)).replace("GMT+0530 (India Standard Time)", "")}</TableCell>
                    <TableCell>
                        <Link
                            to={{
                                pathname: '/edit-item-detail',
                                state: { data: order, category: props.cateList }
                            }
                            }
                        ><i style={{ cursor: 'pointer', margin: '0 5px' }} className="fa fa-edit text-success"></i></Link>
                    </TableCell>
                    {/* <TableCell>
                        <a target='_blank' href={`https://www.ekisandarshan.in/store/${order?.id}`}><i style={{ cursor: 'pointer', margin: '0 5px' }} className="fa fa-info-circle text-primary"></i></a>
                    </TableCell> */}
                    <TableCell>
                        <i onClick={() => openInNewTab(order)} style={{ cursor: 'pointer', margin: '0 5px' }} className="fa fa-info-circle text-primary"></i>
                        {/* <a target='_blank' href={`https://www.ekisandarshan.in/products/${slugify(order?.item?.category || "")}/${slugify((order?.name).split('-')[0] || "")}/${order?.itemId}`}><i style={{ cursor: 'pointer', margin: '0 5px' }} className="fa fa-info-circle text-primary"></i></a> */}
                    </TableCell>
                </TableRow>
                {
                    open && (
                        <TableRow>
                            <TableCell colSpan={24}>
                                <Container>
                                    <Box paddingY="20px">
                                        <Grid container spacing={2}>
                                            {/* Buyer Address */}
                                            {currentBuyer && (
                                                <Grid xs={3}>
                                                    <Typography variant="h6" fontSize="17px" marginBottom="4px">
                                                        Buyer Address
                                                    </Typography>
                                                    <Typography variant="h6" fontSize="15px" marginBottom="4px">
                                                        Address
                                                    </Typography>
                                                    <Typography variant="p" sx={{ color: "text.primary" }} fontSize="14px">
                                                        {currentBuyer.address ? (
                                                            <>
                                                                {currentBuyer.address.address_line_1}, <br />
                                                                {currentBuyer.address.address_line_2}, <br />
                                                            </>
                                                        ) : (
                                                            <>
                                                                {currentBuyer.address_line_1 || "N/A"}, <br />
                                                                {currentBuyer.address_line_2 || "N/A"}, <br />
                                                            </>
                                                        )}
                                                        {currentBuyer.block && `${currentBuyer.block},`} &nbsp;
                                                        {currentBuyer.district || "N/A"}, <br />
                                                        {currentBuyer.state || "N/A"} <br />
                                                    </Typography>
                                                </Grid>
                                            )}

                                            {/* Buyer Account Info */}
                                            {currentBuyer && (
                                                <Grid xs={3}>
                                                    <Typography variant="h6" sx={{ color: "text.primary" }} fontSize="17px" marginBottom="4px">
                                                        Buyer Account Info
                                                    </Typography>
                                                    <Typography variant="p" sx={{ color: "text.primary" }} fontSize="14px">
                                                        <b>Name</b>: {currentBuyer.name || "N/A"}<br />
                                                    </Typography>
                                                    <Typography variant="p" sx={{ color: "text.primary" }} fontSize="14px">
                                                        <b>Phone</b>: {currentBuyer.phone || "N/A"}<br />
                                                    </Typography>
                                                </Grid>
                                            )}

                                            {/* Seller Address */}
                                            {curruntSeller && (
                                                <Grid xs={3}>
                                                    <Typography variant="h6" fontSize="17px" marginBottom="4px">
                                                        Seller Address
                                                    </Typography>
                                                    <Typography variant="p" sx={{ color: "text.primary" }} fontSize="14px">
                                                        {curruntSeller.address ? (
                                                            <>
                                                                {curruntSeller.address.address_line_1}, <br />
                                                                {curruntSeller.address.address_line_2}, <br />
                                                            </>
                                                        ) : (
                                                            <>
                                                                {curruntSeller.address_line_1 || "N/A"}, <br />
                                                                {curruntSeller.address_line_2 || "N/A"}, <br />
                                                            </>
                                                        )}
                                                        {curruntSeller.block && `${curruntSeller.block},`} &nbsp;
                                                        {curruntSeller.district || "N/A"}, <br />
                                                        {curruntSeller.state || "N/A"} <br />
                                                    </Typography>
                                                </Grid>
                                            )}

                                            {/* Seller Account Info */}
                                            {curruntSeller && (
                                                <Grid xs={3}>
                                                    <Typography variant="h6" sx={{ color: "text.primary" }} fontSize="17px" marginBottom="4px">
                                                        Seller Account Info
                                                    </Typography>
                                                    <Typography variant="p" sx={{ color: "text.primary" }} fontSize="14px">
                                                        <b>Name</b>: {curruntSeller.name || "N/A"}<br />
                                                    </Typography>
                                                    <Typography variant="p" sx={{ color: "text.primary" }} fontSize="14px">
                                                        <b>Phone</b>: {curruntSeller.phone || "N/A"}<br />
                                                    </Typography>
                                                    <Typography variant="p" sx={{ color: "text.primary" }} fontSize="14px">
                                                        <b>Seller Type</b>: {curruntSeller.userType || "N/A"}<br />
                                                    </Typography>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Box>
                                </Container>
                            </TableCell>
                        </TableRow>
                    )
                }

            </>
        )

    }
    else {
        return (
            <>
                <TableRow
                    hover
                    key={order.id}
                >
                    <TableCell>
                        <Button disabled onClick={() => open === true ? setOpen(false) : setOpen(true)} >
                            <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />
                        </Button>
                    </TableCell>
                    <TableCell>{props.index + 1}</TableCell>
                    <TableCell>Item has been deleted</TableCell>
                    <TableCell>{order?.quantity}</TableCell>
                    <TableCell>Item has been deleted</TableCell>
                    <TableCell>Item has been deleted</TableCell>
                    <TableCell>{String(new Date(order.timeStamp)).replace("GMT+0530 (India Standard Time)", "")}</TableCell>
                    <TableCell>
                        <Link
                            to={{
                                pathname: '/edit-item-detail',
                                state: { data: order, category: props?.cateList }
                            }
                            }
                        ><i style={{ cursor: 'pointer', margin: '0 5px' }} className="fa fa-edit text-success"></i></Link>
                    </TableCell>
                    <TableCell>
                        <i onClick={deleteItem} style={{ cursor: 'pointer', margin: '0 5px' }} className="fa fa-trash text-danger"></i>
                    </TableCell>
                    <TableCell>
                        {/* <i onClick={showDetail} style={{ cursor: 'pointer', margin: '0 5px' }} className="fa fa-info-circle text-primary"></i> */}
                    </TableCell>
                </TableRow>

            </>
        )
    }
}
function ItemsTr(props) {
    const deleteItem = () => {
        Swal.fire(
            'Do you really want to delelte these item?',
            '',
            'warning'
        ).then(() => {

            const ItemRef = firebase.database().ref('items/').child(props.list.id);
            ItemRef.remove().then(() => {
                Swal.fire(
                    'Deleted Successfull',
                    "",
                    'success'
                )
            })



        })

    }
    const showDetail = () => {

        const list = props.list
        const theDetial = () => {
            var detail = ""
            Object.keys(list).map((col) => { detail = detail + `${col}: ${list[col]} <br>` })
            return detail
        }

        Swal.fire("The detail", `<div className="text-left">${theDetial()}</div>`, "info")
        console.log(list)

    }
    if (props.category === '') {
        return (
            <TableRow key={props?.index + 6}>
                <TableCell>{props?.index + 1}</TableCell>
                <TableCell>{props?.list?.name}</TableCell>
                <TableCell>&#8377; {props?.list?.price}</TableCell>
                <TableCell>{props?.list?.unit}</TableCell>
                <TableCell>{String(new Date(props?.list?.timeStamp)).replace("GMT+0530 (India Standard Time)", "")}</TableCell>
                <TableCell>{props?.list?.quantity}</TableCell>
                <TableCell>{props?.list?.category}</TableCell>
                <TableCell>
                    <Link
                        to={{
                            pathname: '/edit-item-detail',
                            state: { data: props?.list, category: props?.cateList }
                        }
                        }
                    ><i style={{ cursor: 'pointer', margin: '0 5px' }} className="fa fa-edit text-success"></i></Link>
                </TableCell>
                <TableCell>
                    <i onClick={deleteItem} style={{ cursor: 'pointer', margin: '0 5px' }} className="fa fa-trash text-danger"></i>
                </TableCell>
                <TableCell>
                    <i onClick={showDetail} style={{ cursor: 'pointer', margin: '0 5px' }} className="fa fa-info-circle text-primary"></i>
                </TableCell>
            </TableRow>
        )
    }
    else {
        return (
            props?.category !== props?.list?.category ? '' :
                <TableRow key={props?.index}>
                    <TableCell>{props?.index + 1}</TableCell>
                    <TableCell>{props?.list?.name}</TableCell>
                    <TableCell>&#8377; {props?.list?.price}</TableCell>
                    <TableCell>{props?.list?.unit}</TableCell>
                    <TableCell>{String(new Date(props?.list?.timeStamp)).replace("GMT+0530 (India Standard Time)", "")}</TableCell>
                    <TableCell>{props?.list?.quantity}</TableCell>
                    <TableCell>{props?.list?.category}</TableCell>
                    <TableCell>
                        <Link
                            to={{
                                pathname: '/edit-item-detail',
                                state: { data: props?.list, category: props?.cateList }
                            }
                            }
                        ><i style={{ cursor: 'pointer', margin: '0 5px' }} className="fa fa-edit text-success"></i></Link>
                    </TableCell>
                    <TableCell>
                        <i onClick={deleteItem} style={{ cursor: 'pointer', margin: '0 5px' }} className="fa fa-trash text-danger"></i>
                    </TableCell>
                    <TableCell>
                        <i onClick={showDetail} style={{ cursor: 'pointer', margin: '0 5px' }} className="fa fa-info-circle text-primary"></i>
                    </TableCell>

                </TableRow>
        )
    }
}
export default ManageOrders

