// import { useState } from "react"
// import { useLocation } from "react-router-dom"
// import StateDisctrict from "../../Components/StateDisctrict"
// /* eslint-disable */
// function EditItemDetail() {

//     const data =  useLocation()
//     const [itemDetail, setItemDetail] = useState({
//     })

//     let name, value;
//     const handleFormChanges = (event) => {
//         name = event.target.name;
//         value = event.target.value;
//         setItemDetail({ ...itemDetail, [name]: value });
//     }

//     console.log("state data",data)


//     return (

//         <div style={{ backgroundColor: "red", overflowY: "auto", minHeight: "100vh" }} className="container mt-5">
//             <div className="row">
//                 <div className="col-lg-12">
//                     <div className="form">
//                         <form action="" >
//                             <div className="form-group my-4">
//                                 <label className="form-label">Name</label>
//                                 <input onChange={handleFormChanges}
//                                     required value={data?.state?.data?.item?.name || data?.state?.data?.name}
//                                     className="form-control" name="name" type="text" placeholder="Enter Item Name" />
//                             </div>
//                             <div className="row">
//                                 <div className="form-group col-lg-6 my-4">
//                                     <label className="form-label">Price</label>
//                                     <input onChange={handleFormChanges}
//                                         required value={data?.state?.data?.item?.price || data?.state?.data?.price}
//                                         className="form-control" name="price" type="number" placeholder="Enter Item Price" />
//                                 </div>
//                                 <div className="form-group col-lg-6 my-4">
//                                     <label className="form-label">Unit</label>
//                                     <input onChange={handleFormChanges}
//                                         required value={data?.state?.data?.unit||data?.state?.data?.item?.unit}
//                                         className="form-control" name="unit" type="text" />
//                                 </div>
//                             </div>
//                             <div className="form-group my-4">
//                                 <label className="form-label">Available Quantity</label>
//                                 <input onChange={handleFormChanges}
//                                     required value={data?.state?.data?.quantity}
//                                     className="form-control" name="quantity" type="number" />
//                             </div>
//                             <div className="form-group my-4">
//                                 <label className="form-label">Seller Name</label>
//                                 <input onChange={handleFormChanges}
//                                     required value={data?.state?.data?.sellerName || data?.state?.data?.item?.sellerName}
//                                     className="form-control" name="seller_name" type="text" />
//                             </div>
//                             <div className="form-group my-4">
//                                 <label className="form-label">Item Image</label>
//                                 <input onChange={(e) => { setItemDetail({ ...itemDetail, item_image: e.target.files[0] }) }}
//                                     required 
//                                     className="form-control" name="item_image" type="file" />
//                             </div>
//                             <StateDisctrict formData={itemDetail} handleFormChanges={handleFormChanges} />
//                             <div className="form-group my-4">
//                                 <label className="form-label">Category</label>
//                                 <select onChange={handleFormChanges} className="form-control" name="category" id="">
//                                     <option value="">Select Category</option>
//                                     {/* {data?.state &&

//                                         data?.state?.category.map((item, index) => {
//                                             return (
//                                                 <option key={index} value={item.categorieName}>{item.categorieName}</option>
//                                             )
//                                         })
//                                     } */}
//                                 </select>
//                             </div>

//                             <button className="btn  btn-success" type="submit"  >Add Item <i className="fa fa-upload"></i></button>

//                         </form>
//                     </div>
//                 </div>

//             </div>
//         </div>
//     )
// }

// export default EditItemDetail


import { useState } from "react";
import { useLocation } from "react-router-dom";
import StateDisctrict from "../../Components/StateDisctrict";
import { updateItemDetails } from "../../util/orders/orders";

function EditItemDetail() {
    const data = useLocation();
    const [itemDetail, setItemDetail] = useState({
        name: data?.state?.data?.name || data?.state?.data?.item?.name || '',
        price: data?.state?.data?.price || data?.state?.data?.item?.price || '',
        unit: data?.state?.data?.unit || data?.state?.data?.item?.unit || '',
        quantity: data?.state?.data?.quantity || '',
        seller_name: data?.state?.data?.sellerName || data?.state?.data?.item?.sellerName || '',
        category: '',
    });

    const handleFormChanges = (event) => {
        const { name, value } = event.target;
        setItemDetail((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleFileChange = (e) => {
        setItemDetail((prevState) => ({
            ...prevState,
            item_image: e.target.files[0],
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const updatedFields = {
            name: itemDetail.name,
            price: itemDetail.price,
            unit: itemDetail.unit,
            quantity: itemDetail.quantity,
            seller_name: itemDetail.seller_name,
            // Only include fields that were changed
        };

        const success = await updateItemDetails(data?.state?.data?.id, updatedFields);
        if (success) {
            window.location.href = "/products/all";
            alert("Item details updated successfully!");
        } else {
            alert("Failed to update item details.");
        }
    };

    return (
        <div style={{ display: 'flex', width: "100vw", flexDirection: 'column', height: '100vh' }}>
            <div style={{ overflowY: "auto", flex: 1 }} className="container mt-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="form">
                            <form onSubmit={handleSubmit}>
                                <div className="form-group my-4">
                                    <label className="form-label">Name</label>
                                    <input
                                        onChange={handleFormChanges}
                                        required
                                        value={itemDetail.name}
                                        className="form-control"
                                        name="name"
                                        type="text"
                                        placeholder="Enter Item Name"
                                    />
                                </div>
                                <div className="row">
                                    <div className="form-group col-lg-6 my-4">
                                        <label className="form-label">Price</label>
                                        <input
                                            onChange={handleFormChanges}
                                            required
                                            value={itemDetail.price}
                                            className="form-control"
                                            name="price"
                                            type="number"
                                            placeholder="Enter Item Price"
                                        />
                                    </div>
                                    <div className="form-group col-lg-6 my-4">
                                        <label className="form-label">Unit</label>
                                        <input
                                            onChange={handleFormChanges}
                                            required
                                            value={itemDetail.unit}
                                            className="form-control"
                                            name="unit"
                                            type="text"
                                        />
                                    </div>
                                </div>
                                <div className="form-group my-4">
                                    <label className="form-label">Available Quantity</label>
                                    <input
                                        onChange={handleFormChanges}
                                        required
                                        value={itemDetail.quantity}
                                        className="form-control"
                                        name="quantity"
                                        type="number"
                                    />
                                </div>
                                <div className="form-group my-4">
                                    <label className="form-label">Seller Name</label>
                                    <input
                                        onChange={handleFormChanges}
                                        required
                                        value={itemDetail.seller_name}
                                        className="form-control"
                                        name="seller_name"
                                        type="text"
                                    />
                                </div>
                                {/* <div className="form-group my-4">
                  <label className="form-label">Item Image</label>
                  <input
                    onChange={handleFileChange}
                    className="form-control"
                    name="item_image"
                    type="file"
                  />
                </div> */}
                                <StateDisctrict formData={itemDetail} handleFormChanges={handleFormChanges} />
                                <div className="form-group my-4">
                                    <label className="form-label">Category</label>
                                    <select
                                        onChange={handleFormChanges}
                                        className="form-control"
                                        name="category"
                                        value={itemDetail.category}
                                    >
                                        <option value="">Select Category</option>
                                        {/* Add category options dynamically */}
                                    </select>
                                </div>
                                <button style={{ marginTop: "30px", marginBottom: "100px" }} className="btn btn-success" type="submit">
                                    Add Item <i className="fa fa-upload"></i>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditItemDetail;
