import { Box, Button, TextField, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import SecondMenu from '../Components/SecondMenu';
import SlideMenu from '../Components/SlideMenu';
import { useDataLayerValue } from '../DataLayer/DataLayer';
import firebase from '../firebase';
import { getMessagesBySellerId } from '../util/chat/fetchChats';
import { CloseFullscreen, CloseOutlined, Transform } from '@mui/icons-material';
/* eslint-disable */
function ExpertTalk() {
    const [data, setData] = useState([]);
    const [allExpertMessages, setAllExpertMessages] = useState([])
    const [showMessages, setShowMssages] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");  // State for search query

    useEffect(() => {
        const loadData = () => {
            async function getData() {
                firebase.database().ref('expert_chats/').on('value', (snapshot) => {
                    const rawUserData = [];
                    const snapVal = snapshot.val();

                    for (let id in snapVal) {
                        rawUserData.push({ ...snapVal[id], id: id });
                    }

                    // Filter out messages where userName is 'admin'
                    const filteredData = rawUserData.filter(item => item.userName !== 'admin');

                    // Reverse and set the filtered data
                    setData(filteredData.reverse());
                });
            }
            getData();
        };
        loadData();
    }, []);

    const deleteItem = (id, imgUrl) => {
        const urlToName = (url) => {

            return String(url.split("/")[7].replace("expert_chats%2F", "").split("?alt")[0].replace("%5B", "[").replace("%5D", "]"))
        }
        Swal.fire(
            'Do you really want to delelte category?',
            '',
            'warning'
        ).then((event) => {
            if (event.isConfirmed) {

                const ItemRef = firebase.database().ref('expert_chats/').child(id);
                ItemRef.remove().then(() => {
                    firebase.storage().ref("expert_chats").child(urlToName(imgUrl)).delete().then(
                        () => {
                            Swal.fire("Item Deleted Successfully", "", "success")
                        }
                    )


                })
            }
        })

    }
    const reply = async ({ id, sellerUID, message }) => {
        const { value: enterdMessage } = await Swal.fire({
            title: 'Reply to the user',
            input: 'text',
            inputLabel: 'message for farmer',
            // inputValue: inputValue,
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                    return 'You need to write something!'
                }
            }
        })

        if (enterdMessage) {
            const messageData = {
                type: "experts-reply",
                reply: enterdMessage,
                timestamp: Date.now(),
                repliedTo: message
            };
            const experetMessage = {
                imgUrl: "",
                message: enterdMessage,
                read: false,
                sellerUID: sellerUID,
                timeStamp: Date.now(),
                userName: 'admin',
                userPhone: ''
            }

            // Send the message to the "notifications" collection
            firebase.database().ref("users").child(sellerUID).child("notifications").push(messageData)
                .then(() => {
                    return firebase.database().ref("expert_chats").push(experetMessage);
                })
                .then(() => {
                    Swal.fire("Message sent to the user", "Message has been sent to user successfully", "success");
                })
                .catch((error) => {
                    Swal.fire("Error", "An error occurred while sending the message", "error");
                    console.error("Error sending message:", error);
                });
        }
    }

    const view = async (list) => {
        setShowMssages(true)
        const allMessages = await getMessagesBySellerId(list.sellerUID)
        setAllExpertMessages(allMessages)
        // console.log('list',allMessages)
    }

    // Function to filter data based on search query
    const filteredData = data.filter((list) => {
        const query = searchQuery.toLowerCase();
        const time =(new Date(list?.timestamp || list?.timeStamp)).toString().replace("GMT+0530 (India Standard Time)", "")
        return (
            list?.userName?.toLowerCase().includes(query) ||
            list?.userPhone?.toLowerCase().includes(query) ||
            list?.message?.toLowerCase().includes(query) ||
            time?.toLocaleLowerCase().includes(query)
        );
    });

    const handleImageClick = (imgUrl) => {
        setSelectedImage(imgUrl);  // Open the image in popup
    }

    const handleClosePopup = () => {
        setSelectedImage(null);  // Close the image popup
    }

    return (

        <>
            <SlideMenu title="Edit Catrgories" url="/edit-categories" />
            <Box
                sx={{
                    bgcolor: 'background.default',
                    // width: "100%"
                    display: "flex",
                    flex: 1,
                }}
                className="main-display edit-categories"
            >
                <div className="main-child">

                    {showMessages && (
                        <Box
                            sx={(theme) => ({
                                position: "fixed",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: "50%",
                                overflowY: "scroll",
                                backgroundColor: "white",
                                height: "70vh",
                                padding: "20px",
                                zIndex: 1000, // Ensure it appears above other elements
                                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Add a shadow for better visibility
                                borderRadius: "8px",
                                [theme.breakpoints.down("sm")]: {
                                    width: "98%", // Adjust width for smaller screens
                                },
                            })}
                            className="child-message"
                        >
                            <CloseOutlined
                                onClick={() => setShowMssages(!showMessages)}
                                style={{ cursor: "pointer", position: "absolute", marginBottom: "10px", top: 10, right: 10 }}
                            />
                            {allExpertMessages.length > 0 ? (
                                allExpertMessages.map((m, i) => (
                                    <div
                                        key={i}
                                        className={`${m?.userName === "admin" ? "admin-m" : "user-m "}`}
                                    >
                                        {m.message}
                                        <span style={{ fontSize: "12px", marginLeft: "auto" }}>{new Date(m?.timeStamp).toLocaleDateString()}</span>
                                    </div>
                                ))
                            ) : (
                                <div>No Messages to Show ...</div>
                            )}
                        </Box>
                    )}


                    <SecondMenu title="Manage orders" url="/orders" />
                    <div className="container smart-card">
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography
                                sx={{ m: 1, color: 'text.primary', }}
                                variant="h4"
                            >
                                Expert's Messages
                            </Typography>
                        </Box>

                        <TextField
                            variant="outlined"
                            label="Search"
                            fullWidth
                            value={searchQuery}  // Bind value to the state
                            onChange={(e) => setSearchQuery(e.target.value)}  // Update the state on change
                            sx={{ maxWidth: 300 }}  // Optional: Add some width restriction for the search bar
                        />

                        {/* Image Popup */}
                        {selectedImage && (
                            <Box
                                sx={{
                                    position: "fixed",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    width: "500px",
                                    backgroundColor: "white",
                                    padding: "20px",
                                    zIndex: 1000,
                                    height: "500px",
                                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                    borderRadius: "8px",
                                }}
                                className="image-popup"
                            >
                                <CloseOutlined
                                    onClick={handleClosePopup}
                                    style={{ cursor: "pointer", position: "absolute", top: 10, right: 10 }}
                                />
                                <img src={selectedImage} alt="Enlarged" style={{ width: '100%', height: '100%' }} />
                            </Box>
                        )}

                        <Box sx={{ color: 'text.primary', pb: 10, mt: 3, }} className="table-responsive">
                            <TableContainer sx={{ minWidth: 650, backgroundColor: "#111827" }} component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead sx={{ backgroundColor: "#1f2937" }}>
                                        <TableRow>
                                            <TableCell>Sr No.</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Img</TableCell>
                                            <TableCell>Mobile No.</TableCell>
                                            <TableCell>Message</TableCell>
                                            <TableCell>timeStamp</TableCell>
                                            <TableCell colSpan={2} className="text-center">Opration</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredData.map((list, index) => (
                                            <TableRow key={index + 1}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{list.userName}</TableCell>
                                                <TableCell><img style={{ width: '120px', height: "80px", cursor: 'pointer' }} src={list.imgUrl} onClick={() => handleImageClick(list.imgUrl)} /></TableCell>
                                                <TableCell>{list.userPhone}</TableCell>
                                                <TableCell>{list.message}</TableCell>
                                                <TableCell>
                                                    {(new Date(list?.timestamp || list?.timeStamp)).toString().replace("GMT+0530 (India Standard Time)", "")}
                                                </TableCell>
                                                <TableCell onClick={() => deleteItem(list.id, list.imgUrl)} className="text-center"><i style={{ cursor: 'pointer' }} className="fa fa-trash text-danger"></i></TableCell>
                                                <TableCell onClick={() => reply(list)} className="text-center"><i style={{ cursor: 'pointer' }} className="fa fa-edit text-success"></i></TableCell>
                                                <TableCell onClick={() => view(list)} className="text-center"><i style={{ cursor: 'pointer' }} className="fa fa-eye text-success"></i></TableCell>
                                            </TableRow>

                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </div>
                </div>
            </Box>
        </>
    )
}

export default ExpertTalk




