import React, { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Grid from '@mui/material/Grid';
import MainCover from '../../Components/MainCover';
import ChatList from '../../Components/chats/ChatList';
import Chats from '../../Components/chats/Chats';
import firebase from '../../firebase';

const Chat = () => {
    const [users, setUsers] = useState([]); // List of all users
    const [currentUser, setCurrentUser] = useState(null); // Current selected user
    const [triggered, setTriggered] = useState(false)

    useEffect(() => {
        async function getData() {
            firebase.database().ref('users/').on('value', (snapshot) => {
                const rawUserData = [];
                const snapVal = snapshot.val();

                for (let id in snapVal) {
                    const user = snapVal[id];
                    if (user.adminMessages && Object.keys(user.adminMessages).length > 0) {
                        const adminMessages = Object.values(user.adminMessages).map((message) => ({
                            ...message,
                            timestamp: message.timestamp || 0, // Ensure timestamp exists
                        }));

                        rawUserData.push({
                            id,
                            name: user.name,
                            phone: user.phone,
                            adminMessages,
                        });
                    }
                }

                // Sort users by the latest message timestamp
                rawUserData.sort((a, b) => {
                    const lastMessageA = a.adminMessages[a.adminMessages.length - 1]?.timestamp || 0;
                    const lastMessageB = b.adminMessages[b.adminMessages.length - 1]?.timestamp || 0;

                    return lastMessageB - lastMessageA;
                });

                setUsers(rawUserData);
            });
        }

        getData();
    }, []);

    return (
        <MainCover>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={false}
            />
            <h1 className='px-4 mt-4 text-2xl text-[white] font-bold'>CHATS</h1>
            <div className="smart-card">
                <Grid container spacing={2}>
                    {/* Chat List */}
                    <Grid item xs={12} md={4}>
                        <ChatList users={users} setCurrentUser={setCurrentUser} setTriggered={setTriggered} triggered={triggered} />
                    </Grid>
                    {/* Chat Section (Only shows when a user is selected) */}
                    <Grid item xs={12} md={7}>
                        {currentUser && currentUser.adminMessages.length > 0 && (
                            <Chats currentUser={currentUser} />
                        )}
                    </Grid>
                </Grid>
            </div>
        </MainCover>
    );
};

export default Chat;
