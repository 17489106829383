// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   TextField,
//   List,
//   ListItem,
//   ListItemAvatar,
//   Avatar,
//   ListItemText,
//   Typography,
//   Grid,
//   Paper,
//   useMediaQuery,
//   Button,
//   Backdrop,
//   Chip,
//   IconButton,
// } from "@mui/material";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import MainCover from "../../Components/MainCover";
// import { deleteGroup, fetchMessages, sendMessage, showAllGroups } from "../../util/groups/fetchGroups";
// import firebase from "../../firebase";
// import CloseIcon from "@mui/icons-material/Close";
// import { Delete } from "@mui/icons-material";

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#1a202c",
//     },
//     secondary: {
//       main: "#2d3748",
//     },
//   },
//   typography: {
//     fontFamily: "Roboto, sans-serif",
//   },
// });

// const GroupChatUI = () => {
//   const [admin, setAdmin] = useState();
//   const [isChatOpen, setIsChatOpen] = useState(false);  // State to control chat visibility
//   const [allGroups, setAllGroups] = useState([]);
//   const [currentMessages, setCurrentMessages] = useState([]);
//   const [currentGroup, setCurrentGroup] = useState();
  
//   const [myMessage, setMyMessage] = useState("");

//   firebase.auth().onAuthStateChanged(user => {
//     if (user) {
//       setAdmin(user);
//     } else {
//       setAdmin(undefined);
//     }
//   });

//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

//   const getGroupsData = async () => {
//     const data = await showAllGroups();
//     setAllGroups(data);
//   };

//   useEffect(() => {
//     getGroupsData();
//   }, []);

//   const handleCurrentGroup = async (id) => {
//     const data = await fetchMessages(id);
//     setCurrentMessages(Object.values(data));
//     setIsChatOpen(true);  // Open chat panel when group is clicked
//   };

//   const handleSendMessage = async () => {
//     const data = await sendMessage(currentGroup?.id, myMessage, admin);
//     handleCurrentGroup(currentGroup?.id);
//     setMyMessage("");
//   };

//   const handleCloseChat = () => {
//     setIsChatOpen(false);  // Close the chat panel
//   };

//   const handleDeleteGroup = async (id) =>{
//     try{
//       await deleteGroup(id)
//       getGroupsData();
//     }catch(err){
//       console.log(err)
//     }
//   }

//   return (
//     <MainCover>
//       <Backdrop
//         sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
//         open={false}
//       ></Backdrop>
//       <Box
//         sx={{
//           display: "flex",
//           flexDirection: isMobile ? "column" : "row",
//           height: "100vh",
//           backgroundColor: theme.palette.primary.main,
//           color: "#fff",
//         }}
//       >
//         {/* Left Panel - Group List */}
//         <Box
//           sx={{
//             width: isMobile ? "100%" : "30%",
//             height: "80vh",
//             overflowY: "scroll",
//             padding: 2,
//             borderRight: isMobile ? "none" : "1px solid #2d3748",
//             backgroundColor: theme.palette.secondary.main,
//             "&::-webkit-scrollbar": {
//               width: "5px",
//             },
//             "&::-webkit-scrollbar-thumb": {
//               backgroundColor: "#1a202c", // Matte black thumb
//               borderRadius: "10px",
//             },
//             "&::-webkit-scrollbar-thumb:hover": {
//               backgroundColor: "#2d3748", // Slightly lighter on hover
//             },
//             "&::-webkit-scrollbar-track": {
//               backgroundColor: "#4a5568", // Dark grey background
//               borderRadius: "10px",
//             },
//           }}
//         >
//           <List>
//             {allGroups.map((group, index) => (
//               <ListItem
//                 key={index}
//                 sx={{ padding: 1, cursor: "pointer" }}
//                 onClick={() => {
//                   handleCurrentGroup(group.id);
//                   setCurrentGroup(group);
//                 }}
//               >
//                 <ListItemAvatar>
//                   <Avatar sx={{ bgcolor: "#4a5568" }}>{group?.name.substring(0, 1)}</Avatar>
//                 </ListItemAvatar>
//                 <ListItemText
//                   primary={group?.name}
//                   secondary={
//                     Object.values(group?.messages || {})[
//                       Object.values(group?.messages || []).length - 1
//                     ]?.text
//                   }
//                   primaryTypographyProps={{ color: "#fff" }}
//                   secondaryTypographyProps={{ color: "#cbd5e0" }}
//                 />
//                 <Delete sx={{color:"tomato"}} onClick={()=>handleDeleteGroup(group?.id)}/>
//               </ListItem>
//             ))}
//           </List>
//         </Box>

//         {/* Right Panel - Group Chat (Only Visible When Group Is Selected) */}
//         {isChatOpen ? (
//           <Box
//             sx={{
//               width: isMobile ? "100%" : "70%",
//               height: "80vh",
//               padding: 2,
//               display: "flex",
//               flexDirection: "column",
//             }}
//           >
//             <Paper
//               sx={{
//                 flexGrow: 1,
//                 padding: 2,
//                 marginBottom: 2,
//                 backgroundColor: "#2d3748",
//                 overflowY: "auto",
//                 borderRadius: 3,
//                 "&::-webkit-scrollbar": {
//                   width: "5px",
//                 },
//                 "&::-webkit-scrollbar-thumb": {
//                   backgroundColor: "#1a202c", // Matte black thumb
//                   borderRadius: "10px",
//                 },
//                 "&::-webkit-scrollbar-thumb:hover": {
//                   backgroundColor: "#2d3748", // Slightly lighter on hover
//                 },
//                 "&::-webkit-scrollbar-track": {
//                   backgroundColor: "#4a5568", // Dark grey background
//                   borderRadius: "10px",
//                 },
//               }}
//             >
//               <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
//                 <Typography variant="h6" color="#fff" gutterBottom>
//                   {currentGroup?.name}
//                 </Typography>
//                 <IconButton onClick={handleCloseChat} color="inherit">
//                   <CloseIcon />
//                 </IconButton>
//               </Box>
//               {/* Display messages */}
//               {currentMessages.length > 0 ? (
//                 currentMessages.map((message, index) => (
//                   <Box
//                     key={index}
//                     sx={{
//                       padding: 2,
//                       borderRadius: 2,
//                       border: "1px solid #ccc",
//                       maxWidth: "500px",
//                       margin: "10px 0",
//                       backgroundColor: "#28282B",
//                     }}
//                   >
//                     <Typography variant="body2" color="white">
//                       <strong>{message?.sender?.name}</strong>
//                     </Typography>
//                     <Typography variant="body1" color="lightgrey" sx={{ marginTop: 1 }}>
//                       {message?.text}
//                     </Typography>
//                     <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 1 }}>
//                       <Chip
//                         label={
//                           message?.createdAt
//                             ? new Date(message.createdAt).toLocaleString([], {
//                                 hour: "2-digit",
//                                 minute: "2-digit",
//                                 hour12: true,
//                               })
//                             : ""
//                         }
//                         size="small"
//                         color="secondary"
//                       />
//                     </Box>
//                   </Box>
//                 ))
//               ) : (
//                 <Typography variant="body2" color="#cbd5e0">
//                   No Messages to show...
//                 </Typography>
//               )}
//             </Paper>
//             <Box sx={{ display: "flex", alignItems: "center", gap: 2, color: "black", marginTop: "20px", padding: "20px" }}>
//               <TextField
//                 fullWidth
//                 placeholder="Type a message..."
//                 variant="outlined"
//                 sx={{ borderRadius: 2, height: "90%" }}
//                 value={myMessage}
//                 onChange={(e) => setMyMessage(e.target.value)}
//               />
//               <Button variant="contained" color="primary" onClick={handleSendMessage}>
//                 Send
//               </Button>
//             </Box>
//           </Box>
//         )
//         :<Typography variant="body1" sx={{ color: "#fff",padding:"50px" }}>
//           {!isMobile && 
//           "Select group to view messages"}
//           </Typography>
//       }
//       </Box>
//     </MainCover>
//   );
// };

// export default GroupChatUI;
import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Button,
  IconButton,
  Paper,
  useMediaQuery,
  Backdrop,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MainCover from "../../Components/MainCover";
import { deleteGroup, fetchMessages, sendMessage, showAllGroups } from "../../util/groups/fetchGroups";
import firebase from "../../firebase";
import CloseIcon from "@mui/icons-material/Close";
import { Delete } from "@mui/icons-material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1a202c",
    },
    secondary: {
      main: "#2d3748",
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
  },
});

const GroupChatUI = () => {
  const [admin, setAdmin] = useState();
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [allGroups, setAllGroups] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [currentMessages, setCurrentMessages] = useState([]);
  const [currentGroup, setCurrentGroup] = useState();
  const [myMessage, setMyMessage] = useState("");
  const [searchQuery, setSearchQuery] = useState(""); // State for the search query

  firebase.auth().onAuthStateChanged(user => {
    if (user) {
      setAdmin(user);
    } else {
      setAdmin(undefined);
    }
  });

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const getGroupsData = async () => {
    const data = await showAllGroups();
    setAllGroups(data);
    setFilteredGroups(data); // Initially show all groups
  };

  useEffect(() => {
    getGroupsData();
  }, []);

  // Update the list of filtered groups based on search query
  useEffect(() => {
    const filtered = allGroups.filter((group) =>
      group?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase())
    );
    // console.log('filtered items are', filtered)
    setFilteredGroups(filtered);
  }, [searchQuery, allGroups]);

  const handleCurrentGroup = async (id) => {
    const data = await fetchMessages(id);
    setCurrentMessages(Object.values(data));
    setIsChatOpen(true);
  };

  const handleSendMessage = async () => {
    const data = await sendMessage(currentGroup?.id, myMessage, admin);
    handleCurrentGroup(currentGroup?.id);
    setMyMessage("");
  };

  const handleCloseChat = () => {
    setIsChatOpen(false);
  };

  const handleDeleteGroup = async (id) => {
    try {
      await deleteGroup(id);
      getGroupsData();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <MainCover>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={false}></Backdrop>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          height: "100vh",
          backgroundColor: theme.palette.primary.main,
          color: "#fff",
        }}
      >
        {/* Left Panel - Group List */}
        <Box
          sx={{
            width: isMobile ? "100%" : "30%",
            height: "80vh",
            overflowY: "scroll",
            padding: 2,
            borderRight: isMobile ? "none" : "1px solid #2d3748",
            backgroundColor: theme.palette.secondary.main,
            "&::-webkit-scrollbar": {
              width: "5px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#1a202c",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#2d3748",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#4a5568",
              borderRadius: "10px",
            },
          }}
        >
                <h1 className='px-4 mt-4 text-2xl text-[white] font-bold'>Group-Chats</h1>
          {/* Search Bar */}
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search by group name..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ marginBottom: 2, marginTop: 2 }}
          />
          
          <List>
            {filteredGroups.map((group, index) => (
              <ListItem
                key={index}
                sx={{ padding: 1, cursor: "pointer" }}
                onClick={() => {
                  handleCurrentGroup(group.id);
                  setCurrentGroup(group);
                }}
              >
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "#4a5568" }}>{group?.name.substring(0, 1)}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={group?.name}
                  secondary={
                    group?.createdBy?.name
                    // Object.values(group?.messages || {})[
                    //   Object.values(group?.messages || []).length - 1
                    // ]?.text
                  }
                  primaryTypographyProps={{ color: "#fff" }}
                  secondaryTypographyProps={{ color: "#cbd5e0" }}
                />
                <Delete sx={{ color: "tomato" }} onClick={() => handleDeleteGroup(group?.id)} />
              </ListItem>
            ))}
          </List>
        </Box>

        {/* Right Panel - Group Chat */}
        {isChatOpen ? (
          <Box
            sx={{
              width: isMobile ? "100%" : "70%",
              height: "80vh",
              padding: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Paper
              sx={{
                flexGrow: 1,
                padding: 2,
                marginBottom: 2,
                backgroundColor: "#2d3748",
                overflowY: "auto",
                borderRadius: 3,
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography variant="h6" color="#fff" gutterBottom>
                  {currentGroup?.name}
                </Typography>
                <IconButton onClick={handleCloseChat} color="inherit">
                  <CloseIcon />
                </IconButton>
              </Box>
              {/* Display messages */}
              {currentMessages.length > 0 ? (
                currentMessages.map((message, index) => (
                  <Box
                    key={index}
                    sx={{
                      padding: 2,
                      borderRadius: 2,
                      border: "1px solid #ccc",
                      maxWidth: "500px",
                      margin: "10px 0",
                      backgroundColor: "#28282B",
                    }}
                  >
                    <Typography variant="body2" color="white">
                      <strong>{message?.sender?.name}</strong>
                    </Typography>
                    <Typography variant="body1" color="lightgrey" sx={{ marginTop: 1 }}>
                      {message?.text}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography variant="body2" color="#cbd5e0">
                  No Messages to show...
                </Typography>
              )}
            </Paper>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, color: "black", marginTop: "20px", padding: "20px" }}>
              <TextField
                fullWidth
                placeholder="Type a message..."
                variant="outlined"
                sx={{ borderRadius: 2, height: "90%" }}
                value={myMessage}
                onChange={(e) => setMyMessage(e.target.value)}
              />
              <Button variant="contained" color="primary" onClick={handleSendMessage}>
                Send
              </Button>
            </Box>
          </Box>
        ) : (
          <Typography variant="body1" sx={{ color: "#fff", padding: "50px" }}>
            {!isMobile && "Select a group to view messages"}
          </Typography>
        )}
      </Box>
    </MainCover>
  );
};

export default GroupChatUI;




