import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { Box, Button, TextField, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SecondMenu from '../../Components/SecondMenu'
import SlideMenu from '../../Components/SlideMenu'
import firebase from '../../firebase'
import SearchIcon from '@mui/icons-material/Search';

/* eslint-disable  */
function Farmer() {
    const [data, setData] = useState([]);
    const [search, setSearch] = useState([]);
    const [searchQuery, setSearchQuery] = useState("")

    useEffect(() => {
        const loadData = () => {
            async function getData() {
                firebase.database().ref('users/').on('value', (snapshot) => {
                    const rawUserData = [];
                    const snapVal = snapshot.val();
                    for (let id in snapVal) {
                        if (snapVal[id].userType === "farmer") {
                            rawUserData.push({ ...snapVal[id], id: id });
                        }
                    }

                    const sortedRawData = rawUserData.sort((a, b) => b.timeStamp - a.timeStamp);

                    // console.log('sorted data', sortedRawData)

                    setData(sortedRawData);
                    setSearch(sortedRawData); // Initialize search with the same sorted data
                });
            }
            getData();
        };
        loadData();
    }, [searchQuery]);  // Assuming searchQuery is for filtering/searching, not sorting



    const handleChange = (event) => {
        setSearchQuery(event.target.value)
    }

    const searchFun = () => {
        if (searchQuery.trim() !== "") {
            const newData = data.filter((item) => {
                const time = String(new Date(item?.timeStamp)).replace("GMT+0530 (India Standard Time)", "")
                return (
                    (item?.name?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
                        item?.phone?.includes(searchQuery) ||
                        item?.state?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
                        item?.district?.toLowerCase()?.includes(searchQuery.toLowerCase())) ||
                        time?.toLocaleLowerCase().includes(searchQuery?.toLocaleLowerCase())
                )
            }
            );
            const sortedRawData = newData.sort((a, b) => b.timeStamp - a.timeStamp);
            // console.log('sortedData', sortedRawData)
            setSearch(sortedRawData); // Update search state
        } else {
            const sortedRawData = data.sort((a, b) => b.timeStamp - a.timeStamp);
            // console.log('d',sortedRawData)
            setSearch(sortedRawData); // Reset search to full dataset
        }
    };
    // firebase.database().ref('contact-form/').on('child_added', ()=>audio.play())
    // firebase.database().ref('contact-form/').on('child_removed', ()=>remove.play())
    return (
        <>
            <SlideMenu title="Edit Catrgories" url="/edit-categories" />
            <Box
                sx={{
                    bgcolor: 'background.default',
                    // width: "100%"
                    display: "flex",
                    flex: 1,
                }}
                className="main-display edit-categories"
            >
                <div className="main-child">
                    <SecondMenu title="Manage orders" url="/orders" />
                    <div className="container smart-card">


                        <Typography
                            sx={{ m: 1, color: 'text.primary', }}
                            variant="h4"
                        >
                            Farmers
                        </Typography>
                        <Box>
                            <TextField onChange={handleChange} label="Search" sx={{ width: "400px" }} placeholder='Search by Name, Phone Number, state, district and block' />
                            <Button onClick={searchFun} sx={{ height: "56px", marginLeft: 2 }} variant='contained' color="primary">
                                <SearchIcon />
                            </Button>
                        </Box>
                        <Box sx={{ color: 'text.primary', pb: 10, mt: 3, }} className="table-responsive">

                            <TableContainer sx={{ minWidth: 650, backgroundColor: "#111827" }} component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead sx={{ backgroundColor: "#1f2937" }}>
                                        <TableRow>
                                            <TableCell>Sr No.</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>User Type</TableCell>
                                            <TableCell>Phone No.</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>State</TableCell>
                                            <TableCell>District</TableCell>
                                            <TableCell sx={{ minWidth: "200px" }}>Last Update</TableCell>
                                            <TableCell sx={{ minWidth: "200px" }}>Date of Registration</TableCell>
                                            <TableCell sx={{ minWidth: "200px" }}>Address</TableCell>
                                            <TableCell sx={{ minWidth: "200px" }}>Chemical Fertilizers</TableCell>
                                            <TableCell sx={{ minWidth: "100px" }}>Irrigation</TableCell>
                                            <TableCell sx={{ minWidth: "140px" }}>Soil Tested</TableCell>
                                            <TableCell sx={{ minWidth: "200px" }}>Farm 1: Crop Name</TableCell>
                                            <TableCell sx={{ minWidth: "200px" }}>Farm 1: Crop Type</TableCell>
                                            <TableCell sx={{ minWidth: "200px" }}>Farm 1: Date Of Sowing</TableCell>
                                            <TableCell sx={{ minWidth: "200px" }}>Farm 1: Field Area</TableCell>
                                            {/* <TableCell sx={{ minWidth: "200px" }}>Irrigation Fecility</TableCell> */}
                                            <TableCell sx={{ minWidth: "200px" }} colSpan={2} className="text-center">Opration</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {search.map((item, index) => (
                                            <UserTR index={index} list={item} />

                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </div>
                </div>

            </Box>
        </>
    )
}

function UserTR(props) {
    const list = props.list

    const deleteItem = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it'
        }).then((result) => {
            if (result.isConfirmed) {
                // Perform all deletions and wait for them to complete before deleting the user
                Promise.all([
                    deleteFromOrders(),
                    deleteFromItems(),
                    deleteFromItemToVerify(),
                    deleteFromOrderTrack()
                ])
                    .then(() => {
                        // Deleting the user after all deletions are complete
                        const item = firebase.database().ref('users/').child(list.id);
                        item.remove().then(() => {
                            Swal.fire("User Deleted Successfully", "", "success");
                        }).catch((error) => {
                            Swal.fire("Error deleting user", error.message, "error");
                        });
                    })
                    .catch((error) => {
                        Swal.fire("Error deleting data", error.message, "error");
                    });
            }
        });
    };

    const deleteFromOrders = () => {
        return new Promise((resolve, reject) => {
            firebase.database().ref("orders").once("value", (snap) => {
                var snapVal = snap.val();
                let promises = [];
                for (let id in snapVal) {
                    if (snapVal[id].userId === list.id || snapVal[id].sellerId === list.id) {
                        console.log(`Deleting order: ${id}`);
                        promises.push(firebase.database().ref("orders").child(id).remove());
                    }
                }
                // Ensure all promises are handled correctly
                Promise.all(promises).then(resolve).catch(reject);
            }).catch((error) => reject(error));
        });
    };

    const deleteFromItems = () => {
        return new Promise((resolve, reject) => {
            firebase.database().ref("items").once("value", (snap) => {
                var snapVal = snap.val();
                let promises = [];
                for (let id in snapVal) {
                    if (snapVal[id].sellerUID === list.id) {
                        console.log(`Deleting item: ${id}`);
                        promises.push(firebase.database().ref("items").child(id).remove());
                    }
                }
                // Ensure all promises are handled correctly
                Promise.all(promises).then(resolve).catch(reject);
            }).catch((error) => reject(error));
        });
    };

    const deleteFromItemToVerify = () => {
        return new Promise((resolve, reject) => {
            firebase.database().ref("item-to-verify").once("value", (snap) => {
                var snapVal = snap.val();
                let promises = [];
                for (let id in snapVal) {
                    if (snapVal[id].sellerUID === list.id) {
                        console.log(`Deleting item-to-verify: ${id}`);
                        promises.push(firebase.database().ref("item-to-verify").child(id).remove());
                    }
                }
                // Ensure all promises are handled correctly
                Promise.all(promises).then(resolve).catch(reject);
            }).catch((error) => reject(error));
        });
    };

    const deleteFromOrderTrack = () => {
        return new Promise((resolve, reject) => {
            firebase.database().ref("order-track").once("value", (snap) => {
                var snapVal = snap.val();
                let promises = [];
                for (let id in snapVal) {
                    if (snapVal[id].buyerId === list.id || snapVal[id].sellerId === list.id) {
                        console.log(`Deleting order-track: ${id}`);
                        promises.push(firebase.database().ref("order-track").child(id).remove());
                    }
                }
                // Ensure all promises are handled correctly
                Promise.all(promises).then(resolve).catch(reject);
            }).catch((error) => reject(error));
        });
    };
    const showDetail = () => {
        delete list.farmerData
        delete list.product_for_sell
        delete list.item_rejected
        delete list.cart
        delete list.orders
        delete list.corporateData

        // console.log('farmdata', list.farmerData)


        const theDetial = () => {
            var detail = ""
            Object.keys(list).map((col) => { detail = detail + `${col}: ${list[col]} <br>` })
            return detail
        }

        Swal.fire("The detail", `<div className="text-left">${theDetial()}</div>`, "info")
        // console.log(list)

    }
    var timestamp = list.timeStamp
    var rawTime = String(new Date(timestamp))
    var timestamp = ""
    var lastUpdate = ""
    if (list.farmerData) {
        if ("lastUpdate" in list.farmerData) {
            var lastUpdateStr = String(new Date(list.farmerData.lastUpdate))
            if (lastUpdateStr !== "Invalid Date") {
                lastUpdate = rawTime.replace("GMT+0530 (India Standard Time)", "")

            }
        }
    }
    if (rawTime === "Invalid Date") {
        timestamp = ""
    }
    else {
        timestamp = rawTime.replace("GMT+0530 (India Standard Time)", "")
    }
    return (
        <tr key={props.index}>
            <TableCell align="left">{props.index + 1}</TableCell>
            <TableCell align="left">{list.name}</TableCell>
            <TableCell align="left">{list.userType}</TableCell>
            <TableCell align="left">{list.phone}
            </TableCell>
            <TableCell align="left">{list.email}</TableCell>
            <TableCell align="left">{list.state}</TableCell>
            <TableCell align="left">{list.district}
            </TableCell>
            <TableCell align="left">{lastUpdate}</TableCell>
            <TableCell align="left">{timestamp}</TableCell>
            <TableCell align="left">{list.address?.address_line_1 || ""}
            </TableCell>
            {/* {console.log('list.farmerData', list.farmerData)} */}
            {
                list.farmerData ?

                    <>
                        <TableCell>{list?.farmerData.chemicalFertilizers ? "Yes" : "No"}</TableCell>
                        <TableCell>{list?.farmerData.irrigation ? "Yes" : "No"}</TableCell>
                        <TableCell>{list?.farmerData.soilTested ? "Yes" : "No"}</TableCell>
                        {
                            list?.farmerData.crops ?
                                <>
                                    <TableCell>{list?.farmerData?.crops[0]?.cropName}</TableCell>
                                    <TableCell>{list?.farmerData?.crops[0]?.cropType}</TableCell>
                                    <TableCell>{list?.farmerData?.crops[0]?.dateOfSowing}</TableCell>
                                    <TableCell>{list?.farmerData?.crops[0]?.fieldArea}</TableCell>
                                </>
                                :

                                <>
                                    <TableCell>{list.farmerData.farms ? list?.farmerData?.farms[0]?.cropName : " "}</TableCell>
                                    <TableCell>{list.farmerData.farms ? list?.farmerData?.farms[0]?.cropType : " "}</TableCell>
                                    <TableCell>{list.farmerData.farms ? list?.farmerData.farms[0]?.dateOfSowing : " "}</TableCell>
                                    <TableCell>{list.farmerData.farms ? list?.farmerData?.farms[0]?.fieldArea : " "} {list.farmerData.farms ? list?.farmerData?.farms[0]?.fieldSizeUnit : ""}</TableCell>
                                </>

                        }
                        {/* <TableCell>{list?.farmerData.irrigation}</TableCell> */}
                    </>
                    :
                    <>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        {/* <TableCell></TableCell> */}
                    </>

            }
            <TableCell className="text-center">
                <i onClick={deleteItem} style={{ cursor: 'pointer', margin: '0 5px' }} className="fa fa-trash text-danger"></i>
            </TableCell>
            <TableCell className="text-center">
                <i onClick={showDetail} style={{ cursor: 'pointer', margin: '0 5px' }} className="fa fa-info-circle text-primary" aria-hidden="true"></i>
            </TableCell>
        </tr>
    )
}

export default Farmer