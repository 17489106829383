// import React, { useEffect, useState } from 'react';
// import {
//     Box, Button, Container, Grid, Paper, TableContainer, Typography,
//     Table, TableBody, TableCell, TableHead, TableRow
// } from '@mui/material';
// import { Link } from 'react-router-dom';
// import Swal from 'sweetalert2';
// import firebase from '../../firebase';
// import SlideMenu from '../../Components/SlideMenu';
// import SecondMenu from '../../Components/SecondMenu';
// import { ItemsTr } from './ManageItems';

// function ManageRentProducts() {
//     const [rentItemsList, setRentItemsList] = useState([]);
//     const [cateList, setCateList] = useState([]);

//     useEffect(() => {
//         const loadData = () => {
//             firebase.database().ref('items/').on('value', (snapshot) => {
//                 const snapVal = snapshot.val();
//                 const itemsArray = [];

//                 for (let id in snapVal) {
//                     if (snapVal[id]?.isRented) {
//                         itemsArray.push({ id, ...snapVal[id] });
//                     }
//                 }

//                 setRentItemsList(itemsArray);

//                 firebase.database().ref('categories/').on('value', (snapshot) => {
//                     const categoriesVal = snapshot.val();
//                     const categoriesArray = [];

//                     for (let id in categoriesVal) {
//                         categoriesArray.push({ id, ...categoriesVal[id] });
//                     }

//                     setCateList(categoriesArray);
//                 });
//             });
//         };
//         loadData();
//     }, []);

//     return (
//         <>
//             <SlideMenu title="Edit Categories" url="/edit-categories" />
//             <Box
//                 sx={{
//                     bgcolor: 'background.default',
//                     display: "flex",
//                     flex: 1,
//                 }}
//                 className="main-display manage-rent-products"
//             >
//                 <div className="main-child">
//                     <SecondMenu title="Manage Rent Products" url="/rent-orders" />
//                     <div className="container smart-card">
//                         <Box sx={{ display: "flex", justifyContent: "space-between" }}>
//                             <Typography sx={{ m: 1, color: 'text.primary' }} variant="h4">
//                                 Rented Products
//                             </Typography>
//                             {/* <Box sx={{ display: "flex", alignItems: "center" }}>
//                                 <Link to="/add-rent-product">
//                                     <Button variant='contained'>
//                                         Add Rent Product
//                                     </Button>
//                                 </Link>
//                             </Box> */}
//                         </Box>

//                         <Box sx={{ color: 'text.primary', pb: 10, mt: 3 }} className="table-responsive">
//                             <TableContainer sx={{ minWidth: 650, backgroundColor: "#111827" }} component={Paper}>
//                                 <Table aria-label="simple table">
//                                     <TableHead sx={{ backgroundColor: "#1f2937" }}>
//                                         <TableRow>
//                                             <TableCell>Sr No.</TableCell>
//                                             <TableCell>Name</TableCell>
//                                             <TableCell>Price</TableCell>
//                                             <TableCell>Unit</TableCell>
//                                             <TableCell>Time Stamp</TableCell>
//                                             <TableCell>Last Update</TableCell>
//                                             <TableCell>Quantity</TableCell>
//                                             <TableCell>Category</TableCell>
//                                             <TableCell colSpan={3} className="text-center">Operation</TableCell>
//                                         </TableRow>
//                                     </TableHead>
//                                     <TableBody>
//                                         {
//                                             rentItemsList.map((item, index) => (
//                                                 <ItemsTr
//                                                 category={item?.category || ''}
//                                                     key={index}
//                                                     cateList={cateList}
//                                                     list={item}
//                                                     index={index}
//                                                 />
//                                             ))
//                                         }
//                                     </TableBody>
//                                 </Table>
//                             </TableContainer>
//                         </Box>
//                     </div>
//                 </div>
//             </Box>
//         </>
//     );
// }

// export default ManageRentProducts;



import React, { useEffect, useState } from 'react';
import {
    Box, Button, Container, Grid, Paper, TableContainer, Typography,
    Table, TableBody, TableCell, TableHead, TableRow, TextField
} from '@mui/material';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import firebase from '../../firebase';
import SlideMenu from '../../Components/SlideMenu';
import SecondMenu from '../../Components/SecondMenu';
import { ItemsTr } from './ManageItems';

function ManageRentProducts() {
    const [rentItemsList, setRentItemsList] = useState([]);
    const [cateList, setCateList] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");  // Search query state

    useEffect(() => {
        const loadData = () => {
            firebase.database().ref('items/').on('value', (snapshot) => {
                const snapVal = snapshot.val();
                const itemsArray = [];

                for (let id in snapVal) {
                    if (snapVal[id]?.isRented) {
                        itemsArray.push({ id, ...snapVal[id] });
                    }
                }

                setRentItemsList(itemsArray);

                firebase.database().ref('categories/').on('value', (snapshot) => {
                    const categoriesVal = snapshot.val();
                    const categoriesArray = [];

                    for (let id in categoriesVal) {
                        categoriesArray.push({ id, ...categoriesVal[id] });
                    }

                    setCateList(categoriesArray);
                });
            });
        };
        loadData();
    }, []);

    // Filter rentItemsList based on search query
    const filteredRentItems = rentItemsList.filter((item) => {
        const query = searchQuery.toLowerCase();
        return (
            item?.id?.toLowerCase().includes(query) ||  // Filter by ID
            item?.name?.toLowerCase().includes(query) || // Filter by Name
            item?.category?.toLowerCase().includes(query) // Filter by Category
        );
    });

    return (
        <>
            <SlideMenu title="Edit Categories" url="/edit-categories" />
            <Box
                sx={{
                    bgcolor: 'background.default',
                    display: "flex",
                    flex: 1,
                }}
                className="main-display manage-rent-products"
            >
                <div className="main-child">
                    <SecondMenu title="Manage Rent Products" url="/rent-orders" />
                    <div className="container smart-card">
                        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
                            <Typography sx={{ m: 1, color: 'text.primary' }} variant="h4">
                                Rented Products
                            </Typography>
                            {/* Search Bar */}
                            <TextField
                                variant="outlined"
                                label="Search by ID, Name, Category"
                                fullWidth
                                value={searchQuery}  // Bind value to the search query state
                                onChange={(e) => setSearchQuery(e.target.value)}  // Update search query
                                sx={{ maxWidth: 400 }}  // Optional: Add a width limit to the search bar
                            />
                        </Box>

                        <Box sx={{ color: 'text.primary', pb: 10, mt: 3 }} className="table-responsive">
                            <TableContainer sx={{ minWidth: 650, backgroundColor: "#111827" }} component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead sx={{ backgroundColor: "#1f2937" }}>
                                        <TableRow>
                                            <TableCell>Sr No.</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Price</TableCell>
                                            <TableCell>Unit</TableCell>
                                            <TableCell>Time Stamp</TableCell>
                                            <TableCell>Last Update</TableCell>
                                            <TableCell>Quantity</TableCell>
                                            <TableCell>Category</TableCell>
                                            <TableCell colSpan={3} className="text-center">Operation</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            filteredRentItems.map((item, index) => (
                                                <ItemsTr
                                                    category={item?.category || ''}
                                                    key={index}
                                                    cateList={cateList}
                                                    list={item}
                                                    index={index}
                                                />
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </div>
                </div>
            </Box>
        </>
    );
}

export default ManageRentProducts;

