// import Avatar from '@mui/material/Avatar';
// import Badge from '@mui/material/Badge';
// import Divider from '@mui/material/Divider';
// import Grid from '@mui/material/Grid';
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import TextField from '@mui/material/TextField';
// import React, { useEffect, useState } from 'react';
// import firebase from "../../firebase";
// import { makeStyles } from '@mui/styles';

// const useStyles = makeStyles({
//     list: {
//         maxHeight: '70vh',
//         overflowY: 'auto',
//     },
//     listItem: {
//         padding: '10px',
//     },
//     avatar: {
//         backgroundColor: '#7582eb',
//     },
//     chatInfo: {
//         flexGrow: 1,
//         paddingLeft: '10px',
//     },
// });

// const ChatList = ({ setCurrentUser, setTriggered, triggered }) => {
//     const [data, setData] = useState([]);
//     const classes = useStyles();

//     useEffect(() => {
//         async function getData() {
//             firebase.database().ref('users/').on('value', (snapshot) => {
//                 const rawUserData = [];
//                 const snapVal = snapshot.val();
//                 for (let id in snapVal) {
//                     const currentUser = snapVal[id];
//                     if (currentUser.adminMessages) {
//                         const adminMessages = Object.values(currentUser.adminMessages);
//                         rawUserData.push({ id: id, name: currentUser.name, phone: currentUser.phone, adminMessages });
//                     }
//                 }
//                 rawUserData.sort((a, b) => {
//                     return b?.adminMessages[b?.adminMessages.length - 1].timestamp - a?.adminMessages[a?.adminMessages.length - 1].timestamp;
//                 });
//                 setData(rawUserData);
//             });
//         }
//         getData();
//     }, [triggered]);

//     return (
//         <Grid container direction="column" className={classes.list}>
//             <Grid item xs={12} style={{ padding: '10px' }}>
//                 <TextField id="outlined-basic-email" label="Search" variant="outlined" fullWidth />
//             </Grid>
//             <Divider />
//             <List>
//                 {data?.map((item, index) => {
//                     return (
//                         <ListItem
//                             key={index}
//                             button
//                             onClick={() => {setCurrentUser(item)
//                               setTriggered(!triggered)
//                             }}
//                             className={classes.listItem}
//                         >
//                             <ListItemIcon>
//                                 <Avatar className={classes.avatar}>{item?.name?.substring(0, 1)}</Avatar>
//                             </ListItemIcon>
//                             <div className={classes.chatInfo}>
//                                 <h4 style={{ fontSize: '14px', color:"white" }}>{item?.name}</h4>
//                                 <h6 style={{ fontSize: '12px', fontWeight: 'normal', color:"lightgrey" }}>{item?.phone}</h6>
//                             </div>
//                             {item.adminMessages.some((msg) => msg?.senderName !== 'expert') && (
//                                 <Badge color="secondary" badgeContent="" variant="dot" />
//                             )}
//                         </ListItem>
//                     );
//                 })}
//             </List>
//         </Grid>
//     );
// };

// export default ChatList;



import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import firebase from "../../firebase";
import { makeStyles } from '@mui/styles';
import { Delete } from '@mui/icons-material';

const useStyles = makeStyles({
    list: {
        maxHeight: '70vh',
        overflowY: 'auto',
    },
    listItem: {
        padding: '10px',
    },
    avatar: {
        backgroundColor: '#7582eb',
    },
    chatInfo: {
        flexGrow: 1,
        paddingLeft: '10px',
    },
});

const ChatList = ({ setCurrentUser, setTriggered, triggered }) => {
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");  // State for the search query
    const classes = useStyles();

    useEffect(() => {
        async function getData() {
        firebase.database().ref('users/').on('value', (snapshot) => {
            const rawUserData = [];
            const snapVal = snapshot.val();
            for (let id in snapVal) {
                const currentUser = snapVal[id];
                if (currentUser.adminMessages) {
                    const adminMessages = Object.values(currentUser.adminMessages);
                    rawUserData.push({ id: id, name: currentUser.name, phone: currentUser.phone, adminMessages });
                }
            }
            // Sort data by the latest message timestamp
            rawUserData.sort((a, b) => {
                const latestMessageA = a?.adminMessages?.[a.adminMessages.length - 1]?.timestamp || 0;
                const latestMessageB = b?.adminMessages?.[b.adminMessages.length - 1]?.timestamp || 0;
                return latestMessageB - latestMessageA;  // Latest message first
            });
            setData(rawUserData);
        });
    }
        getData();
    }, [triggered]);

    // Filtered data based on search query
    const filteredData = data.filter((item) => {
        const query = searchQuery?.toLowerCase();
        return (
            item?.name?.toLowerCase()?.includes(query) || 
            item?.phone?.includes(query)  // Filter based on both name and phone
        );
    });

    const deleteAdminMessages = (userId) => {
        return new Promise((resolve, reject) => {
            const userMessagesRef = firebase.database().ref(`users/${userId}/adminMessages`);
    
            // Use set() to remove all adminMessages by setting it to null
            userMessagesRef.set(null)
                .then(() => resolve())
                .catch((error) => {
                    console.error('Error deleting admin messages:', error);
                    reject(error);
                });
        });
    };
    
    

    const handleDeleteChat = (item) =>{
        // console.log('item', item)
        deleteAdminMessages(item?.id);
    }

    return (
        <Grid container direction="column" className={classes.list}>
            <Grid item xs={12} style={{ padding: '10px' }}>
                <TextField
                    id="outlined-basic-email"
                    label="Search"
                    variant="outlined"
                    fullWidth
                    value={searchQuery}  // bind value to state
                    onChange={(e) => setSearchQuery(e.target.value)}  // Update state on change
                />
            </Grid>
            <Divider />
            <List>
                {filteredData?.map((item, index) => {
                    return (
                        <ListItem
                            key={index}
                            button
                            className={classes.listItem}
                        >
                            <ListItemIcon>
                                <Avatar className={classes.avatar}>{item?.name?.substring(0, 1)}</Avatar>
                            </ListItemIcon>
                            <div className={classes.chatInfo} onClick={() => { 
                                setCurrentUser(item);
                                setTriggered(!triggered);
                            }}>
                                <h4 style={{ fontSize: '14px', color: "white" }}>{item?.name}</h4>
                                <h6 style={{ fontSize: '12px', fontWeight: 'normal', color: "lightgrey" }}>{item?.phone}</h6>
                            </div>
                            <Delete sx={{ color: "tomato" }} onClick={()=>handleDeleteChat(item)}/>
                            {/* {item.adminMessages.some((msg) => msg?.senderName !== 'expert') && (
                                <Badge color="secondary" badgeContent="" variant="dot" />
                            )} */}
                        </ListItem>
                    );
                })}
            </List>
        </Grid>
    );
};

export default ChatList;


