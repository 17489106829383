// import { Box, Button, TextField, Typography } from '@mui/material';
// import Paper from '@mui/material/Paper';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import React, { useEffect, useState } from 'react';
// import Swal from 'sweetalert2';
// import SecondMenu from '../Components/SecondMenu';
// import SlideMenu from '../Components/SlideMenu';
// import { useDataLayerValue } from '../DataLayer/DataLayer';
// import firebase from '../firebase';
// import MainCover from '../Components/MainCover';
// /* eslint-disable */
// function EnquiryMails() {
//     const [data, setData] = useState([]);


//     useEffect(() => {
//         const loadData = () => {
//             async function getData() {
//                 firebase.database().ref('enquiry/').on('value', (snapshot) => {
//                     const rawUserData = []
//                     var snapVal = snapshot.val();
//                     for (let id in snapVal) {
//                         rawUserData.push({ ...snapVal[id], id: id })
//                     }
//                     setData(rawUserData.reverse())
//                 })


//             }
//             getData()
//         }
//         loadData()
//     }, [])
//     const deleteItem = (id) => {
//         Swal.fire(
//             'Do you really want to delelte category?',
//             '',
//             'warning'
//         ).then(() => {

//             const ItemRef = firebase.database().ref('enquiry/').child(id);
//             ItemRef.remove().then(() => {
//                 Swal.fire(
//                     'Deleted Successfull',
//                     "",
//                     'success'
//                 )
//             })



//         })
//     }
//     return (


//         <MainCover>
//             <div className="container smart-card">
//                 <Box sx={{ display: "flex", justifyContent: "space-between" }}>
//                     <Typography
//                         sx={{ m: 1, color: 'text.primary', }}
//                         variant="h4"
//                     >
//                         Product Enquiry Mails
//                     </Typography>
//                 </Box>

//                 <Box sx={{ color: 'text.primary', pb: 10, mt: 3, }} className="table-responsive">
//                     <TableContainer sx={{ minWidth: 650, backgroundColor: "#111827" }} component={Paper}>
//                         <Table aria-label="simple table">
//                             <TableHead sx={{ backgroundColor: "#1f2937" }}>
//                                 <TableRow>
//                                     <TableCell>Sr No.</TableCell>
//                                     <TableCell>Name</TableCell>
//                                     <TableCell>State</TableCell>
//                                     <TableCell>District</TableCell>
//                                     <TableCell>Phone</TableCell>
//                                     <TableCell>Crop Category</TableCell>
//                                     <TableCell>Crop Name</TableCell>
//                                     <TableCell>Other if mentioned</TableCell>
//                                     <TableCell>details</TableCell>
//                                     <TableCell className="text-center">Opration</TableCell>
//                                 </TableRow>
//                             </TableHead>
//                             <TableBody>
//                                 {data.map((list, index) => (
//                                     <TableRow key={index + 1}>
//                                         <TableCell>{index + 1}</TableCell>
//                                         <TableCell>{list.full_name}</TableCell>
//                                         <TableCell>{list.state}</TableCell>
//                                         <TableCell>{list.district}</TableCell>
//                                         <TableCell>{list.phone}</TableCell>
//                                         <TableCell>{list.category}</TableCell>
//                                         <TableCell>{list.cropName}</TableCell>
//                                         <TableCell>{list.other}</TableCell>
//                                         <TableCell>{list.details}</TableCell>
//                                         <TableCell onClick={() => deleteItem(list.id)} className="text-center"><i style={{ cursor: 'pointer' }} className="fa fa-trash text-danger"></i></TableCell>
//                                         {/* <TableCell style={{ minWidth: 185 }}>{timestamp}</td> */}
//                                     </TableRow>
//                                 ))}
//                             </TableBody>
//                         </Table>
//                     </TableContainer>
//                 </Box>

//             </div>
//         </MainCover>

//     )
// }

// export default EnquiryMails


import { Box, Button, TextField, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import SecondMenu from '../Components/SecondMenu';
import SlideMenu from '../Components/SlideMenu';
import { useDataLayerValue } from '../DataLayer/DataLayer';
import firebase from '../firebase';
import MainCover from '../Components/MainCover';

function EnquiryMails() {
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");  // State for search query

    useEffect(() => {
        const loadData = () => {
            async function getData() {
                firebase.database().ref('enquiry/').on('value', (snapshot) => {
                    const rawUserData = [];
                    const snapVal = snapshot.val();
                    for (let id in snapVal) {
                        rawUserData.push({ ...snapVal[id], id: id });
                    }
                    setData(rawUserData.reverse());
                });
            }
            getData();
        };
        loadData();
    }, []);

    // Function to handle deleting an item
    const deleteItem = (id) => {
        Swal.fire(
            'Do you really want to delete this enquiry?',
            '',
            'warning'
        ).then(() => {
            const ItemRef = firebase.database().ref('enquiry/').child(id);
            ItemRef.remove().then(() => {
                Swal.fire(
                    'Deleted Successfully',
                    "",
                    'success'
                );
            });
        });
    };

    // Function to filter data based on search query
    const filteredData = data.filter((list) => {
        const query = searchQuery.toLowerCase();
        return (
            list?.full_name?.toLowerCase().includes(query) || 
            list?.state?.toLowerCase().includes(query) ||
            list?.district?.toLowerCase().includes(query) ||
            list?.phone?.includes(query) ||
            list?.category?.toLowerCase().includes(query)
        );
    });

    return (
        <MainCover>
            <div className="container smart-card">
                <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
                    <Typography sx={{ m: 1, color: 'text.primary' }} variant="h4">
                        Product Enquiry Mails
                    </Typography>
                    {/* Search Bar */}
                    <TextField
                        variant="outlined"
                        label="Search"
                        fullWidth
                        value={searchQuery}  // Bind value to the state
                        onChange={(e) => setSearchQuery(e.target.value)}  // Update the state on change
                        sx={{ maxWidth: 300 }}  // Optional: Add some width restriction for the search bar
                    />
                </Box>

                <Box sx={{ color: 'text.primary', pb: 10, mt: 3 }} className="table-responsive">
                    <TableContainer sx={{ minWidth: 650, backgroundColor: "#111827" }} component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead sx={{ backgroundColor: "#1f2937" }}>
                                <TableRow>
                                    <TableCell>Sr No.</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>State</TableCell>
                                    <TableCell>District</TableCell>
                                    <TableCell>Phone</TableCell>
                                    <TableCell>Crop Category</TableCell>
                                    <TableCell>Crop Name</TableCell>
                                    <TableCell>Other if mentioned</TableCell>
                                    <TableCell>Details</TableCell>
                                    <TableCell className="text-center">Operation</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredData.map((list, index) => (
                                    <TableRow key={index + 1}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{list.full_name}</TableCell>
                                        <TableCell>{list.state}</TableCell>
                                        <TableCell>{list.district}</TableCell>
                                        <TableCell>{list.phone}</TableCell>
                                        <TableCell>{list.category}</TableCell>
                                        <TableCell>{list.cropName}</TableCell>
                                        <TableCell>{list.other}</TableCell>
                                        <TableCell>{list.details}</TableCell>
                                        <TableCell onClick={() => deleteItem(list.id)} className="text-center">
                                            <i style={{ cursor: 'pointer' }} className="fa fa-trash text-danger"></i>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </div>
        </MainCover>
    );
}

export default EnquiryMails;

