import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDataLayerValue } from '../../../DataLayer/DataLayer';
import { useState } from 'react';
import TextField from '@mui/material/TextField';

const ProductAnalytics = ({ productQuantity }) => {
    const [{ products, crops }] = useDataLayerValue();
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredProducts, setFilteredProducts] = useState(productQuantity);

    const handleSearchChange = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);
        filterProducts(query);
    };

    const filterProducts = (query) => {
        const filtered = productQuantity.filter(item =>
            item.name.toLowerCase().includes(query) || item.categroy.toLowerCase().includes(query)
        );
        setFilteredProducts(filtered);
    };

    return (
        <div>
            <TextField
                value={searchQuery}
                onChange={handleSearchChange}
                label="Search Products"
                variant="outlined"
                fullWidth
                sx={{ marginBottom: 2 }}
            />

            <TableContainer sx={{ minWidth: 650, backgroundColor: "#111827" }} component={Paper}>
                <Table aria-label="simple table">
                    <TableHead sx={{ backgroundColor: "#1f2937" }}>
                        <TableRow>
                            <TableCell align="left">Sr No.</TableCell>
                            <TableCell align="left">Product Name</TableCell>
                            <TableCell align="left">Product Quantity</TableCell>
                            <TableCell align="left">Unit</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            filteredProducts.map((item, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align="left">{index + 1}</TableCell>
                                    <TableCell align="left">{item.name} ({item.itemCount})</TableCell>
                                    <TableCell align="left">{item.quantity}</TableCell>
                                    <TableCell align="left">{item.categroy.includes("Farm Machinery") ? "Unit" : "KG"}</TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default ProductAnalytics;
