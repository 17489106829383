import React, { useState } from 'react'
import $ from 'jquery'
import Blocks from './Blocks'
import { UISelect } from "./UI/index"
import { FormControl, MenuItem } from '@mui/material'
import { useDataLayerValue } from '../DataLayer/DataLayer'
import { IndianDistrict, IndianStates } from './Geographical Data/Index'
function StateDisctrict(props) {
    const { handleFormChanges } = props
    const [optionBlock, setOptionBlock] = useState(undefined)
    const [optionDistrict, setOptionDistrict] = useState(undefined)
    function changeState(event) {
        var StateSelected = event.target.value;
        setOptionDistrict(IndianDistrict[StateSelected])
        if (StateSelected === "") {
            setOptionDistrict(undefined)
        }
        handleFormChanges(event)
    }


    const districtChange = (event) => {
        var district = event.target.value
        setOptionBlock(Blocks[district])
        handleFormChanges(event)

    }

    return (
        <>
            <FormControl fullWidth sx={{ my: 2 }}>
                <UISelect name="state" label="State" onChange={changeState} id="inputState">
                    <MenuItem value="">Select a State - राज्य चुनें</MenuItem>
                    {
                        IndianStates.map((item, index) => {
                            return (
                                <MenuItem key={index} value={item}>{item}</MenuItem>
                            )
                        })
                    }
                </UISelect>

            </FormControl>
            <FormControl fullWidth sx={{ my: 2 }}>
                {
                    props.formData.state === undefined || optionDistrict === undefined ?
                        <UISelect name="district" label="Select a State First" id="inputDistrict">
                        </UISelect>
                        : <UISelect value={props.formData.state} onChange={districtChange} name="district" label="Select a District">
                            {
                                optionDistrict.map((item) => {
                                    return <MenuItem value={item}>{item}</MenuItem>
                                })
                            }
                        </UISelect>
                }
            </FormControl>

            <FormControl fullWidth sx={{ my: 2 }}>
                {
                    props.formData.district === undefined || optionBlock === undefined ?
                        <UISelect label="Select District First" disabled name="block"
                            id="inputBlocks">
                        </UISelect>
                        : <UISelect onChange={handleFormChanges} name="block"

                            label="Select Block" id="inputBlocks">
                            {
                                optionBlock.map((item) => {
                                    return <MenuItem value={item}>{item}</MenuItem>
                                })
                            }
                        </UISelect>
                }
            </FormControl>
        </>
    )
}

export default StateDisctrict